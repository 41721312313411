import React, { useRef, useEffect, useState } from "react";

import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { textRenderer } from 'handsontable/renderers/textRenderer';

import 'handsontable/dist/handsontable.full.css';

import {
    Flex,
    IconButton,
} from '@chakra-ui/react';

import {
    AiOutlineInsertRowAbove,
    AiOutlineInsertRowBelow,
    AiOutlineDeleteRow,
} from 'react-icons/ai';

import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';

// register Handsontable's modules
registerAllModules();

export function MySheet(props) {
    const [current, setCurrent] = useState(props.current);
    const [data, setData] = useState(props.data);
    const [columns, setColumns] = useState(props.columns);
    const [selected, setSelected] = useState([]);

    // テーブル
    const hotTableComponent = useRef(null);

    if (current !== props.current) {
        setCurrent(props.current);
        setData(props.data);
        setColumns(props.columns);
    }

    function hot() {
        return hotTableComponent.current.hotInstance;
    }

    function onUpdate(v) {
        props.onUpdate(null, columns, v);
    }

    const [settings, setSettings] = useState(() => {
        const initialState = {
            licenseKey: 'non-commercial-and-evaluation',
            data: data,
            columns: columns,
            colHeaders: true,
            rowHeaders: true,
            width: "calc(100vw - 250px)",
            height: "calc(100vh - 48px - 48px)",
            // contextMenu: true,
            // colWidths: 100,
            // 列のリサイズを許可
            colWidths: 100,
            manualColumnResize: true,
            // 列の移動を許可
            // manualColumnMove: true,
            // 行の移動を許可
            // manualRowMove: true,
            // 前後の空白をトリムしない
            // trimWhitespace: false,
            afterSelectionEnd: (row, column, row2, column2, selectionLayerLevel) => {
                if (column > column2) {
                    const tmp = column2;
                    column2 = column;
                    column = tmp;
                }
                if (row > row2) {
                    const tmp = row2;
                    row2 = row;
                    row = tmp;
                }
                // console.log(column, row, column2, row2);
                setSelected([column, row, column2, row2]);
                // if (props.onSelected) {
                //     props.onSelected(column, row, column2, row2);
                // }
            },
            afterChange: function (changes, source) {
                if (!changes) {
                    return;
                }
                // console.log(changes, source)
                // データが変更されたとき
                // changes
                // [[[0]行目(0はじまり), [1]カラム名, [2]変更前, [3]変更後], ...]
                // handsonの場合、変更があったセルのみ変更する仕様にする。
                // onUpdateStyle(changes);
                const v = hot().getSourceData();
                setData(v);
                onUpdate(v);
            },
        }

        return initialState;
    });

    return (
        <div id="hot-app">
            {renderToolbar()}
            <HotTable
                ref={hotTableComponent}
                settings={settings}
            />
        </div>
    );

    function renderToolbar() {
        return (
            <Flex
                h={'48px'}
                p={2}>
                <IconButton size='sm'
                    onClick={() => { insertRow(); }}
                    variant="outline"
                    colorScheme={'gray'}
                    icon={<AiOutlineInsertRowAbove />}
                />
                <IconButton size='sm'
                    onClick={() => { addRow(); }}
                    variant="outline"
                    colorScheme={'gray'}
                    icon={<AiOutlineInsertRowBelow />}
                />
                <IconButton size='sm'
                    onClick={() => { removeRow(); }}
                    variant="outline"
                    colorScheme={'gray'}
                    icon={<AiOutlineDeleteRow />}
                />
            </Flex>
        );
    }

    // 行を追加する
    function insertRow() {
        if (selected.length > 0) {
            // console.log(selected);
            hot().alter('insert_row', selected[1], selected[3] - selected[1] + 1);
        }
    }

    function addRow() {
        if (selected.length > 0) {
            // console.log(selected);
            hot().alter('insert_row', selected[3] + 1, selected[3] - selected[1] + 1);
        }
    }

    // 行を削除する
    function removeRow() {
        if (selected.length > 0) {
            // console.log(selected);
            hot().alter('remove_row', selected[1], selected[3] - selected[1] + 1);
        }
    }
}
