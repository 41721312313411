import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth } from '../firebase.js'

import config from '../config';
import TimeService from './TimeService.js';

class ContractService {

    api = config.api;

    async getApiAuthorizationAsync(apiid, apikey) {
        // トークンの認証切れに対応
        let token = null;
        const currentUser = firebaseAuth.currentUser;
        if (currentUser) {
            token = await currentUser.getIdToken();
        } else {
            console.log("ERROR");
        }

        return {
            "Api-Id": apiid,
            "Api-Key": apikey ? apikey : '',
            Authorization: "Bearer " + token
        };
    }

    async postAsync(apiid, command, data) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.post(url, data, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    async getAsync(apiid, command) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.get(url, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    // API契約を作成する
    async newContract(sid, api_id, api_keys) {
        let response = await this.postAsync(sid, config.apipath + "/v1/contract/api/new", {
            api_id: api_id,
            type: 1,
            end: TimeService.formatDate(),
            api_keys: api_keys
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    // API契約を取得する
    async getContract(sid) {
        let response = await this.getAsync(sid, config.apipath + "/v1/contract/api");
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    // APIキーを更新する
    async updateApiKeys(sid, api_keys) {
        let response = await this.postAsync(sid, config.apipath + "/v1/contract/api/keys", {
            api_keys: api_keys
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    async postApiAsync(apiid, apikey, command, data) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.post(url, data, { headers: await this.getApiAuthorizationAsync(apiid, apikey) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    async getApiAsync(apiid, apikey, command) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.get(url, { headers: await this.getApiAuthorizationAsync(apiid, apikey) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    // テナントを作成する
    async createTenantApi(apiId, apiKey, tenantId, tenantName) {
        let response = await this.postApiAsync(apiId, apiKey, "/monshin/v1/tenant/new", {
            id: tenantId,
            name: tenantName,
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    // テナントリストを作成する
    async getTenantsApi(apiId, apiKey) {
        let response = await this.getApiAsync(apiId, apiKey, "/monshin/v1/tenants");
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }
    
    async postApi(apiId, apiKey, url, body) {
        let response = await this.postApiAsync(apiId, apiKey, url, body);
        console.log(response);
        return response;
    }
    async getApi(apiId, apiKey, url) {
        let response = await this.getApiAsync(apiId, apiKey, url);
        console.log(response);
        return response;
    }
}

export default new ContractService();
