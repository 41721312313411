
// import uuid from 'react-uuid';
// import { db, SpacesTable } from './IndexedDB';
// import ServerService from './StorageService';
// import WorkspaceService from './WorkspaceService';
// import ZipService from '../Services/ZipService';
// import imageCompression from 'browser-image-compression';
// import TimeService from '../Services/TimeService';
// import NotebookService from './NotebookDB';

// import { ManualRowMove } from 'handsontable/plugins';
// import LocalDB from './LocalDB';

// import { firebaseAuth, firebaseDatabase } from '../firebase.js'
// import { ref, onValue } from "firebase/database";
// import ProjsDB from './ProjsDB';
// import Compressor from 'compressorjs'

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth } from '../firebase.js'

import config from '../config';

class LogService {
    api = config.api;
    
    async getApiAuthorizationAsync(apiid) {
        // トークンの認証切れに対応
        let token = null;
        const currentUser = firebaseAuth.currentUser;
        if (currentUser) {
            token = await currentUser.getIdToken();
        } else {
            console.log("ERROR");
        }

        const apikey = '';
        return {
            "Api-Id": apiid,
            "Api-Key": apikey,
            Authorization: "Bearer " + token
        };
    }

    async getAsync(apiid, command) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.get(url, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    async postAsync(apiid, command, data) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.post(url, data, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    async getLogs(sid, ym, d) {
        const param = "?ym=" + ym;
        const response = await this.getAsync(sid, "/pockedev/v1/logs" + param);
        // console.log(response);
        if (response && response.status === 200) {
            // 日付をキーにしたobjectを返す
            return response.data;
        } else {
            return null;
        }
    }

    // // アクションログをセーブ
    // action(sid, action) {
    //     // 今日のログを取得
    //     // 今日のログに追記
    //     // 今日のログを保存
    //     SpacesTable.set(db, sid, action);
    // }

    error() {

    }

    // flush(sid, uid, ym, d, text) {
    //     // 昨日までの保存されているログを1日ごとに書き込み
    //     // ユーザーごとの書き込みなので重複があるとするとデバイス違いの場合
    //     // そのケースがあるので、1回取得してから更新して書き込む
    //     WorkspaceService.setLog(sid, uid, ym, d, text);
    // }

    // // アクセスログを記録
    // // offlineでも動くので、アクセスはサーバーからメモを取得する提起ルーチンが動いたときに記録する
    // access(sid, uid) {
    //     const ym = TimeService.getYM();
    //     const d = TimeService.getD();
    //     WorkspaceService.setLog(sid, uid, ym, d, {
    //         load: true
    //     });
    // }
}

export default new LogService();