
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    IconButton,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import ContractService from '../../../Services/ContractService';
import uuid from 'react-uuid';
import Loading from '../Components/Loading';
import Colors from '../../../Constants/Colors';
import Waiting from '../Components/Waiting';

// ワークスペースの設定を行うアプリ
export default function ApiTestManager({
    space, children,
}) {
    const colors = new Colors();
    const gray700 = colors.gray700;
    const white = colors.white;
    const { user, state } = useAuthContext();
    const toast = useToast();

    let [loading, setLoading] = React.useState(false);
    let [apiId, setApiId] = React.useState(null);
    let [key1, setKey1] = React.useState("");
    let [key2, setKey2] = React.useState("");
    let [name, setName] = React.useState("");

    let [output, setOutput] = React.useState("");

    const sid = user.uid;

    useEffect(() => {
        const func = async () => {
            setLoading(true);
            // 最新のプラン情報を得る
            const result = await ContractService.getContract(sid);
            if (result && result.secrets.length === 2) {
                setApiId(user.uid);
                setKey1(result.secrets[0]);
                setKey2(result.secrets[1]);
                console.log(result);
            }
            setLoading(false);
        };
        func();
    }, []);

    function noApi() {
        return (<>
            <Alert status='info'>
                <AlertIcon />
                APIを利用するには、API契約が必要です
            </Alert>
        </>);
    }

    return (
        <Box
            color={gray700}
            bg={white}
            w={'calc(100vw - 200px)'}
            h={"calc(100vh - 48px)"}
            overflowY="auto"
            align="center"
            p={8}
        >
            <Box
                w={"full"}
                maxW={"600px"}
            >
                <VStack spacing={5}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        APIの設定
                    </Heading>

                    {loading ? <Waiting />
                        : !apiId ? noApi() : <>
                            <FormControl align="left">
                                <FormLabel>API ID</FormLabel>
                                <Text>{apiId}</Text>
                            </FormControl>
                            <FormControl align="left">
                                <FormLabel>APIキー</FormLabel>
                                <Flex align="center">
                                    <Text>{key1}</Text>
                                    <Box p={1} />
                                    <Button onClick={async () => {
                                        const key = uuid().replace(/-/g, '');
                                        if (await ContractService.updateApiKeys(sid, key + "," + key2)) {
                                            setKey1(key);
                                        }
                                    }}>更新</Button>
                                </Flex>
                                <Box p={1} />
                                <Flex align="center">
                                    <Text>{key2}</Text>
                                    <Box p={1} />
                                    <Button onClick={async () => {
                                        const key = uuid().replace(/-/g, '');
                                        if (await ContractService.updateApiKeys(sid, key1 + "," + key)) {
                                            setKey2(key);
                                        }
                                    }}>更新</Button>
                                </Flex>
                            </FormControl>

                            <Heading as="h2" size="lg" mt={6} mb={2}>
                                サービス管理者向けの設定
                            </Heading>
                            <FormControl>
                                <FormLabel>APIプランを作成するAPI ID</FormLabel>
                                <Input size='sm' variant='outline' placeholder=''
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />
                            </FormControl>
                            <Button onClick={() => {
                                ContractService.newContract(sid, name, key1 + "," + key2);
                            }}>APIプランを新規作成する</Button>
                        </>}
                </VStack>
            </Box>
        </Box>
    );
}