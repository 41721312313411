
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';
import MonshinService from '../../../Services/MonshinService';

// ワークスペースの設定を行うアプリ
export default function SymptomDbManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(false);
    let { db, setDb } = useAuthContext();
    const toast = useToast();

    let [symptoms, setSymptoms] = React.useState(null);
    let [searchText, setSearchText] = React.useState("");

    const api_url = config.api;
    const success_url = config.host + "/home"

    useEffect(() => {
        if (db) {
            setSymptoms(db.symptoms);
        }
    }, []);

    // // サーバーからISDBをロードする
    // async function load() {
    //     setLoading(true);
    //     db = await MonshinService.getISDB();
    //     setDb(db);
    //     setSymptoms(db.symptoms);
    //     setLoading(false);
    // }

    let n = 0;

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
        h={"calc(100vh - 48px)"}
    >
        <Box
            w={"full"}
            h={"calc(100vh - 48px)"}
            overflowX="auto"
        >
            <Box p={4}>
                <Input
                    variant='outline'
                    placeholder='検索...'
                    onChange={(event) => {
                        setSearchText(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                />
            </Box>
            {loading ? <>
                loading...
            </>
                : <TableContainer>
                    <Table size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th>種別</Th>
                                <Th>選択肢</Th>
                                <Th>分類1</Th>
                                <Th>分類2</Th>
                                <Th>代表語</Th>
                                <Th>未指定時重み</Th>
                                <Th>同義語</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {symptoms ? symptoms.map((d, i) => {
                                const name = d.name;
                                // if (n > 40) {
                                //     return null;
                                // }
                                if (searchText.length > 0) {
                                    let match = false;
                                    for (const s of d.synonyms) {
                                        if (s.indexOf(searchText) !== -1) {
                                            match = true;
                                        }
                                    }
                                    if (!match && name.indexOf(searchText) === -1) {
                                        return null;
                                    }
                                }
                                n++;
                                return (
                                    <Tr>
                                        <Td>{d.type}</Td>
                                        <Td>{d.select}</Td>
                                        <Td>{d.cat1}</Td>
                                        <Td>{d.cat2}</Td>
                                        <Td>{name}</Td>
                                        <Td>{d.defw}</Td>
                                        <Td>
                                            {d.synonyms.join(", ")}
                                        </Td>
                                    </Tr>
                                )
                            }) : null}
                        </Tbody>
                    </Table>
                </TableContainer>}
        </Box>
    </Box>);
}
