import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from "firebase/database";
// import 'firebase/analytics';
// import "firebase/performance";
import config from './config.js';

const aimonConfig = {
    apiKey: "AIzaSyAJkCFK8k8yBZ5z2O5kBdNvKnBt16q8Dn8",
    authDomain: "aimon-7dc94.firebaseapp.com",
    databaseURL: "https://aimon-7dc94.firebaseio.com",
    projectId: "aimon-7dc94",
    storageBucket: "aimon-7dc94.appspot.com",
    messagingSenderId: "217739222789",
    appId: "1:217739222789:web:4a8e1824b72563092f216c",
    measurementId: "G-CRH1CRN9PR"
};

const pockemonConfig = {
    apiKey: "AIzaSyAx6xLo97y4TTW7LmccwkyrirD13nNDxGs",
    authDomain: "pockemon-9499c.firebaseapp.com",
    databaseURL: "https://pockemon-9499c.firebaseio.com/",
    projectId: "pockemon-9499c",
    storageBucket: "pockemon-9499c.appspot.com",
    messagingSenderId: "388624620385",
    appId: "1:388624620385:web:f5d4f618d9213835558320",
    measurementId: "G-CGY96RJT25"

    // apiKey: "AIzaSyAx6xLo97y4TTW7LmccwkyrirD13nNDxGs",
    // authDomain: "pockemon-9499c.firebaseapp.com",
    // projectId: "pockemon-9499c",
    // storageBucket: "pockemon-9499c.appspot.com",
    // messagingSenderId: "388624620385",
    // appId: "1:388624620385:web:f5d4f618d9213835558320",
    // measurementId: "G-CGY96RJT25"
};

let firebaseConfig = config.dev ? aimonConfig : pockemonConfig;
// console.log(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp)
export const googleAuthProvider = new GoogleAuthProvider();
export const firebaseDatabase = getDatabase(firebaseApp)