import { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import { firebaseAuth, firebaseDatabase } from '../firebase.js'
import MonshinService from '../Services/MonshinService.js';
import UserDataService from '../Repositories/UserDataService.js';
import config from '../config.js';

const AuthContext = createContext();

// user.displayName
// user.email
// user.photoURL
// user.uid
export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const location = useLocation();

    // Firebbaseユーザーオブジェクト
    const [user, setUser] = useState('');

    // 認証状態
    const [signing, setSigning] = useState(true);

    // ライセンス
    const [license, setLicense] = useState({});

    // 単語辞書
    const [kana, setKana] = useState({});
    const [words, setWords] = useState({});
    const [items, setItems] = useState({});

    const [db, setDb] = useState(null);

    let loaded = false;

    // データ
    const value = {
        user,
        signing,
        license,
        kana,
        words,
        items,
        db,
        setDb,
    };

    useEffect(() => {
        // console.log(location)
        // if (location.pathname === '/') {
        //     setSigning(false);
        //     return;
        // }
        const unsubscribed = firebaseAuth.onAuthStateChanged(async (user) => {
            setUser(user);
            setSigning(false);

            if (!user) {
                return;
            }

            const uid = user.uid;
            let lics = await UserDataService.getLicenseData(uid);
            if (!lics) {
                lics = {
                    "role": "public"
                };
            }
            // @todo ユーザーのロール設定をいれたら消す
            if (config.dev) {
                lics = {
                    "role": "dev"
                };
            }
            setLicense(lics);

            let state = await UserDataService.getUserData(uid);
            if (!state ) {
                state = {
                    uid: user.uid
                };
                const name = config.appName;
                await UserDataService.createUserData(uid, user, name);
            }

            if (!loaded) {
                loaded = true;
                // public id
                MonshinService.loadList('237b76b15dc40ba6a653608d24aa8',
                    (k, w) => {
                        // console.log(k, w)
                        setKana(k.$word);
                        setWords(w);
                        const items = {};
                        items.structure = {};
                        items.sex = filterWords(w.profile, "gender");
                        items.keyword = filterAllWords(w, "keyword", items.structure);
                        items.onset = chnageLabel(filterWords(w.onset, "onset"));
                        items.duration = chnageLabel(filterWords(w.time_course, "duration"));
                        items.time_course = chnageLabel(filterWords(w.time_course, "time course"));
                        items.repeat = chnageLabel(filterWords(w.time_course, "symptom-free"));
                        setItems(items);
                        // console.log(items);
                    });
            }
        });
        return () => {
            unsubscribed();
        };
    }, []);

    function filterWords(a, category) {
        const result = [];
        for (const k of Object.keys(a)) {
            if (a[k].category.includes(category)) {
                result.push(a[k].label);
            }
        }
        return result;
    }

    function filterAllWords(a, category, structure) {
        const result = [];
        for (const d of Object.keys(a)) {
            for (const k of Object.keys(a[d])) {
                const item = a[d][k];
                if (item.category.includes(category)) {
                    // カテゴリにマッチするリスト
                    result.push({ label: a[d][k].label, synonyms: a[d][k].synonyms });
                }
                if (item.parents && item.parents.length > 0) {
                    // 階層構造
                    for (const parent of item.parents) {
                        if (!Object.keys(structure).includes(parent)) {
                            structure[parent] = [];
                        }
                        structure[parent].push(item.label);
                    }
                }
            }
        }
        return result;
    }

    function chnageLabel(a) {
        const labels = {
            "時間単位": "24時間未満",
            "日単位": "1日以上7日未満",
            "週単位": "1週以上4週未満",
            "月単位": "1ヶ月以上12ヶ月未満",
            "年単位": "1年以上",
            "繰り返す": "間欠期がある",
        }
        const result = {};
        for (const s of a) {
            let label = s;
            if (Object.keys(labels).includes(s)) {
                label = labels[s];
            }
            result[label] = s;
        }
        return result;
    }

    return <AuthContext.Provider value={value}>
        {!signing && children}
    </AuthContext.Provider>;
}