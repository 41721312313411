import { useNavigate, Navigate } from 'react-router-dom';

import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';

import SignService from '../../../Services/SignService';

import SynonymManager from './synonym';
import KanaManager from './kana';
import { useAuthContext } from '../../../Contexts/AuthContext';
import AccountManager from './account';
import WorkspaceManager from './tenant';
import PlanManager from './plan';
import ApiManager from './api';
import TenantManager from './tenant';
import ApiTestManager from './apitest';
import config from '../../../config.js';
import SystemManager from './system';
import IsdbManager from '../Develop/isdb';
import SymptomDbManager from '../Develop/symptomdb.js';
import DiseaseManager from '../Develop/disease.js';
import SampleManager from '../Develop/sample.js';

// ワークスペースの設定を行うアプリ
export default function SettingsApp({
    space, setShowSetting, children,
}) {
    const gray100 = useColorModeValue('gray.100', 'gray.900');

    return (
        <Box minH="100vh" bg={gray100}>
            <Appbar
                space={space}
                setShowSetting={setShowSetting}
            />
            <Setting
                space={space}
            />
        </Box>
    );

}

// 設定画面
const Setting = ({ space, ...rest }) => {
    let [fid, setFid] = React.useState("api");

    return (
        <Flex
            ml={{ base: 0, md: 0 }}
            bg={useColorModeValue('white', 'gray.900')}
        >
            <Sidebar
                fid={fid}
                setFid={setFid}
            />
            <Content
                space={space}
                fid={fid}
            />
        </Flex>
    );
};

const Content = ({ space, fid, ...rest }) => {
    switch (fid) {
        case 'account':
            return (<AccountManager space={space} />);
        case 'api':
            return (<ApiManager space={space} />);
        case 'tenant':
            return (<TenantManager space={space} />);
        // case 'member':
        //     return (<MemberManager space={space} />);
        // case 'group':
        //     return (<TeamManager space={space} />);
        case 'plan':
            return (<PlanManager space={space} />);
        case 'apitest':
            return (<ApiTestManager space={space} />);
        case 'system':
            return (<SystemManager space={space} />);
        case 'isdb':
            return (<IsdbManager space={space} />);
        case 'sample':
            return (<SampleManager space={space} />);
        case 'disease':
            return (<DiseaseManager space={space} />);
        case 'symptoms':
            return (<SymptomDbManager space={space} />);
        default:
            return (<div />);
    }
    if (fid === "synonym") {
        return (<SynonymManager />);
    } else if (fid === "kana") {
        return (<KanaManager />);
    }
    return (<div />);
};

const Sidebar = ({ space, fid, setFid, ...rest }) => {
    const { user, license } = useAuthContext();
    const su = user.email === "yosuke.hirano@harolabo.com";
    const dev = (config.dev || (license && license.role === "dev"));
    return (
        <Box
            paddingTop="2"
            paddingBottom="2"
            // transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={250}
            // pos="fixed"
            h={"calc(100vh - 48px)"}
            overflowX="hidden"
            overflowY="auto"
            {...rest}>

            {dev ? <>
                <Text m={4} fontSize={'0.9em'} color={'gray}'}>DB調整</Text>
                <NavItem key={"isdb"} current={fid} fid={"isdb"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"ISDB"}
                </NavItem>
                {/* <NavItem key={"sample"} current={fid} fid={"sample"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"症例"}
                </NavItem> */}
                <NavItem key={"disease"} current={fid} fid={"disease"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"疾患"}
                </NavItem>
                <NavItem key={"symptoms"} current={fid} fid={"symptoms"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"症状"}
                </NavItem>
            </> : null}

            {/* <NavItem key={"account"} current={fid} fid={"account"} onClick={(fid) => {
                setFid(fid);
            }}
                icon={FiEdit}>
                {"アカウント"}
            </NavItem>
            <Divider m={2} /> */}
            <Text m={4} fontSize={'0.9em'} color={'gray}'}>API</Text>
            <NavItem key={"api"} current={fid} fid={"api"} onClick={(fid) => {
                setFid(fid);
            }}
                icon={FiEdit}>
                {"API"}
            </NavItem>
            <NavItem key={"tenant"} current={fid} fid={"tenant"} onClick={(fid) => {
                setFid(fid);
            }}
                icon={FiEdit}>
                {"テナント"}
            </NavItem>
            <Divider m={2} />
            {/* <NavItem key={"plan"} current={fid} fid={"plan"} onClick={(fid) => {
                setFid(fid);
            }}
                icon={FiEdit}>
                {"プラン"}
            </NavItem> */}

            {su ? <>
                <Divider m={4} />
                <Text m={4} fontSize={'0.9em'} color={'gray}'}>開発者用</Text>
                <NavItem key={"apitest"} current={fid} fid={"apitest"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"API test"}
                </NavItem>
                <NavItem key={"synonym"} current={fid} fid={"synonym"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"同義語の管理"}
                </NavItem>
                <NavItem key={"kana"} current={fid} fid={"kana"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"読み方の管理"}
                </NavItem>
                <NavItem key={"db"} current={fid} fid={"db"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"AI DBの管理"}
                </NavItem>
                <Divider m={2} />
                <NavItem key={"system"} current={fid} fid={"system"} onClick={(fid) => {
                    setFid(fid);
                }}
                    icon={FiEdit}>
                    {"システム"}
                </NavItem>
            </> : null}
        </Box>
    );
};

// バーアイテム
const NavItem = ({ current, fid, onClick, icon, children, ...rest }) => {
    return (
        <Link
            onClick={() => {
                onClick(fid);
            }}
            href="#"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                m="1"
                p="1"
                mx="2"
                px="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={current === fid ? 'cyan.100' : ''}
                color={current === fid ? 'gray' : ''}
                _hover={{
                    bg: 'cyan.100',
                    color: 'gray',
                }}
                fontSize={"sm"}
                {...rest}>
                {icon && (
                    <Icon
                        mr="2"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

// アプリバー
const Appbar = ({ space, setShowSetting, ...rest }) => {
    const { user, license } = useAuthContext();
    const navigate = useNavigate();

    const handleLogout = () => {
        SignService.signOut();
        navigate('/signin');
    };

    const dev = (config.dev && license && license.role === "dev");

    return (
        <>
            <Flex
                ml={{ base: 0, md: 0 }}
                px={{ base: 4, md: 4 }}
                height="12"
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                {...rest}>
                {<CloseButton size='sm'
                    onClick={() => { setShowSetting(false); }}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />}
                <Box p="2">
                    {"設定" + (dev ? "(dev)" : "")}
                </Box>
                <div style={{ flexGrow: 1 }} />
            </Flex>
        </>
    );
};
