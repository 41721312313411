
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { MySheet } from '../Components/mysheet';
import MonshinService from '../../../Services/MonshinService';
import { useNavigate } from 'react-router-dom';
import SignService from '../../../Services/SignService';
import TimeService from '../../../Services/TimeService';
import CaseService from '../../../Repositories/CaseService';

function reverse(obj) {
    const result = {};
    for (const key of Object.keys(obj)) {
        const val = obj[key];
        result[val] = key;
    }
    return result;
}

// 検索履歴
// 課金モードでは、分類して整理できる。
// グラフを表示することができる。
export default function HistoryApp({
    space, setShowHistory, select, children,
}) {
    const { user, items } = useAuthContext();
    const [history, setHistory] = React.useState([]);
    let [updated, setUpdated] = React.useState(0);

    const sid = "_public";

    useEffect(() => {
        const unsubscribed = async () => {
            const data = await MonshinService.getHistory(sid);
            setHistory(data);
            setUpdated(updated + 1);
        };
        unsubscribed();
    }, []);

    function label(s) {
        if (s.length > 20) {
            s = s.substring(0, 20) + "...";
        }
        return s;
    }

    function getMain(v) {
        let age = v.age;
        if (v.age >= 0) { age += "歳" } else { age = "年齢不明" }
        let sex = v.gender;
        return <>{v.positive[0]}<br />
            {"（" + age + " " + sex + "）"}
        </>;
    }

    function getPositive(v) {
        const results = [];
        const ignores = ["男性", "女性", "性別不明",
            "年齢不明", "乳児", "幼児", "小児", "若年", "中年", "高齢", "超高齢"];
        const onset = reverse(items.onset);
        const duration = reverse(items.duration);
        const time_course = reverse(items.time_course);
        const repeat = reverse(items.repeat);
        for (let item of v.positive) {
            if (item === getMain(v) || ignores.includes(item)) {
                continue;
            } else if (Object.keys(onset).includes(item)) {
                item = onset[item];
            } else if (Object.keys(duration).includes(item)) {
                item = duration[item];
            } else if (Object.keys(time_course).includes(item)) {
                item = time_course[item];
            } else if (Object.keys(items.repeat).includes(item)) {
                item = repeat[item];
            }
            results.push(item);
        }

        return results.length > 0 ? results.map((w, i) => {
            return (<>{label(w)}<br /></>)
        }) : "-";
    }

    function getResult(v) {
        return v.result.length > 0 ? v.result.map((w, i) => {
            if (i >= 10) {
                return null;
            }
            return (<>{label(w.disease)}<br /></>)
        }) : "-";
    }

    function getDResult(v) {
        return v.ddxs && v.ddxs.length > 0 ? v.ddxs.map((w, i) => {
            if (i >= 10) {
                return null;
            }
            return (<>{label(w)}<br /></>)
        }) : "-";
    }

    function getIResult(v) {
        return v.exclusions && v.exclusions.length > 0 ? v.exclusions.map((w, i) => {
            if (i >= 10) {
                return null;
            }
            return (<>{label(w)}<br /></>)
        }) : "-";
    }

    function getTimestamp(v) {
        return TimeService.datetimeString(v.timestamp);
        // if (typeof(v.timestamp) === 'string') {
        //     return v.timestamp.substring(0, 10) + ' ' + v.timestamp.substring(11, 16);
        // } else {
        // }
    }

    function selectResult(v) {
        if (select) {
            const id = v.id;
            const main = v.main;
            const age = v.age;
            const sex = v.sex;

            const results = [];
            const ignores = ["男性", "女性", "性別不明",
                "年齢不明", "乳児", "幼児", "小児", "若年", "中年", "高齢", "超高齢"];
            const onset = reverse(items.onset);
            const duration = reverse(items.duration);
            const time_course = reverse(items.time_course);
            const repeat = reverse(items.repeat);
            for (let item of v.positive) {
                if (item === main || ignores.includes(item)) {
                    continue;
                } else if (Object.keys(onset).includes(item)) {
                    item = onset[item];
                } else if (Object.keys(duration).includes(item)) {
                    item = duration[item];
                } else if (Object.keys(time_course).includes(item)) {
                    item = time_course[item];
                } else if (Object.keys(items.repeat).includes(item)) {
                    item = repeat[item];
                }
                results.push(item);
            }

            const case1 = new CaseService({
                main: v.main,
                age: v.age,
                sex: v.gender,
                symptoms: results,
            }, {
                ddxs: v.ddxs,
                exclusions: v.exclusions,
            });
            select(case1);
            setShowHistory(false);
        }
    }

    return (
        <Box>
            <Appbar
                space={space}
                setShowSetting={setShowHistory}
                select={select}
            />
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                w={'calc(100vw)'}
                h={"calc(100vh - 48px)"}
                overflowX="auto"
                overflowY="auto"
            >
                <TableContainer overflowX="unset" overflowY="unset">
                    <Table variant='simple' size='md'>
                        <Thead position="sticky" top={0} zIndex="docked" bg="white">
                            <Tr>
                                <Th>日時</Th>
                                {/* <Th>メールアドレス</Th> */}
                                <Th>主訴</Th>
                                <Th>症状</Th>
                                <Th>疾患候補</Th>
                                <Th>鑑別診断結果</Th>
                                <Th>除外</Th>
                                <Th>ノート</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {history.map((v, i) => {
                                if (user.uid !== v.uid) {
                                    return null;
                                }
                                // 開発者
                                // if (v.uid === "DJhq7TkTE2dosJVWwLUksWZNKg63" || v.uid === "9oUX9D56etfdY7w6Sw7gvZtpLDj1") {
                                //     return null;
                                // }
                                return (<Tr
                                    onClick={() => {
                                        selectResult(v);
                                    }}
                                >
                                    <Td>{getTimestamp(v)}</Td>
                                    {/* <Td>{getUser(v)}</Td> */}
                                    <Td>{getMain(v)}</Td>
                                    <Td>{getPositive(v)}</Td>
                                    <Td>{getResult(v)}</Td>
                                    <Td>{getDResult(v)}</Td>
                                    <Td>{getIResult(v)}</Td>
                                    <Td>{v.note}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* <MySheet
                current={"a"}
                columns={columns}
                data={data}
                onUpdate={(note, columns, data) => {
                    // for (const row of data) {
                    //     if (row.name === 'サイドバーの背景URL') {
                    //         const value = row.value;
                    //         setSidebarUrl(value);
                    //         console.log(value);
                    //     }
                    // }
                }}
            /> */}
            </Box>
        </Box>
    );
}

// アプリバー
const Appbar = ({ space, select, setShowSetting, ...rest }) => {
    const { user, license } = useAuthContext();
    const navigate = useNavigate();

    return (
        <>
            <Flex
                ml={{ base: 0, md: 0 }}
                px={{ base: 4, md: 4 }}
                height="12"
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                {...rest}>
                {<CloseButton size='sm'
                    onClick={() => { setShowSetting(false); }}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />}
                <Box p="2">
                    {"検索履歴" + (select ? "（クリックすると選択した条件で検索します）" : "")}
                </Box>
                <div style={{ flexGrow: 1 }} />

                {/* モバイルのみ表示 */}
                <Text
                    display={{ base: 'flex', md: 'none' }}
                    fontSize="2xl"
                    fontWeight="bold">
                </Text>
            </Flex>
        </>
    );
};
