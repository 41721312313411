
class WordService {
    // 入力文字列にマッチする単語リストを取得する
    analyzeText(items, kana, t) {
        const keyword = items.keyword;

        // ひらがなにマッチする単語を選択
        const kanaresult = [];
        for (const word of Object.keys(kana)) {
            if (kana[word].hiragana.includes(t)) {
                kanaresult.push(word);
            }
        }
        const result = [];
        for (const kanaword of kanaresult) {
            for (const word of keyword) {
                if (result.includes(word.label)) {
                    continue;
                }
                if (word.label === kanaword || word.synonyms.includes(kanaword)) {
                    // 代表語・同義語と一致
                    result.push(word.label);
                }
            }
        }

        // 代表語にマッチする単語を選択
        for (const word of keyword) {
            if (result.includes(word.label)) {
                continue;
            }
            if (word.label.includes(t)) {
                result.push(word.label);
            } else {                
                for (const s of word.synonyms) {
                    if (!result.includes(s) && s.includes(t)) {
                        // 同義語と一致
                        result.push(word.label);
                        break;
                    }
                }
            }
        }

        return result;
    }

}

export default new WordService();