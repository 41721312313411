import moment from 'moment-timezone';

class TimeService {
    
    // UTCタイムスタンプ(ms)
    timestamp(dateString) {
        return moment(dateString).utc().valueOf();
    }
    
    // UTC(ms)→Local日付文字列
    dateString(ts) {
        return moment(ts).format('YYYY-MM-DD');
    }

    // UTC(ms)→Local日付時刻文字列
    datetimeString(ts) {
        return moment(ts).format('YYYY-MM-DD HH:mm');
    }

    formatDate(date) {
        if (!date) {
            date = Date.now();
        }
        return moment(new Date(date)).format('YYYY/MM/DD');
    }

    formatDateTime(date) {
        if (!date) {
            date = Date.now();
        }
        return moment(new Date(date)).format('YYYY-MM-DD-HHmmss');
    }
}

export default new TimeService();