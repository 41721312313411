
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import TimeService from '../../../Services/TimeService';
// ワークスペースの設定を行うアプリ
export default function AccountManager({
    space, children,
}) {
    const { user } = useAuthContext();

    let [name, setName] = React.useState(user.displayName ? user.displayName : "");

    function updateName(name) {
        user.displayName = name;
    }

    return (
        <Box
            color={useColorModeValue('gray.700', 'whiteAlpha.900')}
            bg={useColorModeValue('white', 'gray.900')}
            w={'calc(100vw - 200px)'}
            h={"calc(100vh - 48px)"}
            overflowY="auto"
            align="center"
            p={8}
        >
            {/* {Date.now()}<br />
            {TimeService.timestamp()}<br />
            {TimeService.dateString(TimeService.timestamp())}<br />
            {TimeService.datetimeString(TimeService.timestamp())} */}
            <Box
                w={"full"}
                maxW={"600px"}
            >
                <VStack spacing={5}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        アカウントの設定
                    </Heading>
                    {user.email}
                    <FormControl>
                        <FormLabel>表示名</FormLabel>
                        <Input size='sm' variant='outline' placeholder=''
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            onBlur={() => {
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && name.length > 0) {
                                    updateName(name);
                                }
                            }}
                        />
                    </FormControl>

                    <Button>解約する</Button>
                </VStack>
            </Box>
        </Box>
    );
}