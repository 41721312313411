
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';
import MonshinService from '../../../Services/MonshinService';
import { useDropzone } from 'react-dropzone';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

// ISDBの管理
export default function IsdbManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(false);
    let [uploading, setUploading] = React.useState(false);
    let { user, db, setDb } = useAuthContext();
    const toast = useToast();
    const sid = user.uid;
    const gray200 = useColorModeValue('gray.200', 'gray.700');

    let [diseases, setDeseases] = React.useState([]);
    let [accs, setAccs] = React.useState([]);

    const api_url = config.api;
    const success_url = config.host + "/home"

    useEffect(() => {
    }, []);

    // サーバーからISDBをロードする
    async function load() {
        setLoading(true);
        setDb(null);
        setAccs([]);
        db = await MonshinService.getISDB();
        const diseases = MonshinService.buildIsdb(db);
        setDeseases(diseases);
        setDb(db);
        calc(db, diseases);
        // // 代表語に変換
        // for (const item of db.isdb) {
        //     for (const symptom of item.symptoms) {
        //         const word = getWord(db.symptoms, symptom.name);
        //         if (word) {
        //             symptom.name = word;
        //         } else {
        //             symptom.name = symptom.name + "*";
        //         }
        //     }
        // }
        // setDb(db);
        setLoading(false);
    }

    // // 代表語に変更
    // function getWord(symptoms, s) {
    //     for (const symptom of symptoms) {
    //         if (symptom.name === s || symptom.synonyms.includes(s)) {
    //             return symptom.name;
    //         }
    //     }
    //     return null;
    // }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        onDrop: acceptedFiles => {
            for (const file of acceptedFiles) {
                upload(file);
            }
        }
    });

    async function upload(file) {
        if (uploading) {
            return;
        }
        setUploading(true);
        const res = await MonshinService.postFileAsync(sid, file);
        console.log(res);
        setUploading(false);
        if (res.status === 200) {
            toast({
                title: 'ISDBを更新しました',
                //   description: "We've created your account for you.",
                status: 'success',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'ISDBをアップロードできませんでした',
                //   description: "We've created your account for you.",
                status: 'error',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    async function publish() {
        if (uploading) {
            return;
        }
        setUploading(true);
        const res = await MonshinService.publishISDB(sid);
        setUploading(false);
        if (res.status === 200) {
            toast({
                title: 'ISDBをリリースしました',
                //   description: "We've created your account for you.",
                status: 'success',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    function download() {
        const sheetNameMap = {
            "cases": "症例",
            "isdb": "ISDB",
            "symptoms": "症状所見マスタ",
        };

        const workbook = XLSX.utils.book_new();

        Object.keys(db).forEach((sheetName) => {
            let sheet = db[sheetName];
            if (sheetName === "isdb") {
                // isdbのsymptomsを配列に戻す
                sheet = [];
                for (const item of db.isdb) {
                    const data = {
                        "ソート用（引用）": item.sort,
                        "カテゴリNo": item.catno,
                        "カテゴリ": item.cat,
                        "代表疾患名": item.name,
                        "旧）疾患名": item.old,
                        "担当者": item.type,
                        "有病率（1小↔3大）": item.rate,
                        "アシストもの": item.assist,
                    };
                    let no = 1;
                    for (const symptom of item.symptoms) {
                        data["KEY" + no] = symptom.name;
                        no++;
                        data["LSP" + no] = symptom.lsp;
                        no++;
                        data["NSP" + no] = symptom.nsp;
                        no++;
                    }
                    sheet.push(data);
                }
            }
            const worksheet = XLSX.utils.json_to_sheet(sheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetNameMap[sheetName]);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'isdb_out.xlsx');
    }

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
        h={"calc(100vh - 48px)"}
    >
        <Box
            w={"full"}
            h={"calc(100vh - 48px)"}
            overflowX="auto"
            overflowY="auto"
        >
            <Box p={4}>
                <Box>
                    {"開発版ISDBを調整用ISDBにロード"}
                </Box>
                <Flex p={4} alignItems={"center"}>
                    <Button onClick={load}>load</Button>
                    <Box p={4}>
                        <Text>{db ? "loaded." : "no load."}</Text>
                    </Box>
                </Flex>
            </Box>
            <Box p={4}>
                <Box>
                    {"調整用ISDBのダウンロード"}
                </Box>
                {!loading ? <Box p={4}><Button onClick={download}>download</Button>
                </Box> : "loading..."}
            </Box>
            <Box p={4}>
                <Box>
                    {"開発版ISDBのアップロード"}
                </Box>
                {!uploading ? <Box p={4}><div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Box
                        borderWidth={1}
                        borderColor={gray200}
                        p={8}
                    >
                        {"ISDBファイルをD&D / クリックしてISDBファイルを選択"}
                    </Box>
                </div>
                </Box> : "uploading..."}
            </Box>
            <Box p={4}>
                <Box>
                    {"開発版ISDBを本番用ISDBにリリース"}
                </Box>
                {!uploading ? <Box p={4}><Button size={'sm'} variant='outline'
                    onClick={() => {
                        publish();
                    }}>{"リリース"}</Button>
                </Box> : "uploading..."}
            </Box>
        </Box>
    </Box>);
}
