
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth, firebaseDatabase } from '../firebase.js'
import { ref, set, get, child } from "firebase/database";
import uuid from 'react-uuid';

import config from '../config';

export default class CaseService {
    id = "";
    state = {};
    result = {};

    constructor(s, r) {
        this.id = uuid().replace(/-/g, '');
        if (s) {
            this.state = {
                age: s.age,
                ageId: convertAge(s.age),
                ageLabel: convertAge(s.age),
                sex: s.sex,
                main: s.main,
                symptoms: s.symptoms,
                onset: s.onset,
                duration: s.duration,
                time_course: s.time_course,
                repeat: s.repeat,
            };
            this.result = {
                ddxs: r.ddsx,
                exclusions: r.exclusions,
            };
        } else {
            this.state = {
                age: -1,
                ageId: '年齢不明',
                ageLabel: '年齢不明',
                sex: '性別不明',
                symptoms: [],
            };
            this.result = {
                ddxs: [],
                exclusions: [],
            };
        }
        this.update(this.state, true);
    }

    // ケースを変更する
    update(v, firstable) {
        if (v.age) {
            this.state.age = toNumber(v.age);
            this.state.ageId = convertAge(v.age);
            this.state.ageLabel = (toNumber(v.age) >= 0 ? toNumber(v.age) + "歳" : this.state.ageId);
        }
        if (v.sex) {
            this.state.sex = analyzeGender(v.sex);
        }
        if (v.main) {
            this.state.main = v.main;
        }
        if (v.symptoms) {
            this.state.symptoms = v.symptoms;
        }
        if (v.onset) {
            this.state.onset = v.onset;
        }
        if (v.duration) {
            this.state.duration = v.duration;
        }
        if (v.time_course) {
            this.state.time_course = v.time_course;
        }
        if (v.repeat) {
            this.state.repeat = v.repeat;
        }
        // console.log(this.state);
        return this.state;
    }

    updateResult(v) {
        if (v.ddxs) {
            this.result.ddxs = v.ddxs;
        }
        if (v.exclusions) {
            this.result.exclusions = v.exclusions;
        }
        return this.result;
    }
}

function analyzeGender(v) {
    if (v === "男性" || v === "女性") {
        return v;
    }
    return "性別不明";
}

function toNumber(str) {
    if (typeof str === "string") {
        // 全角数字を半角数字に変換
        str = str.replace(/[０-９]/g, function (match) {
            return String.fromCharCode(match.charCodeAt(0) - 65248);
        });
    }
    return Number(str);
}

export function convertAge(v) {
    const range = [
        [0, "乳児"],
        [6, "幼児"],
        [14, "小児"],
        [34, "若年"],
        [64, "中年"],
        [89, "高齢"],
        [200, "超高齢"]
    ];
    let age = '年齢不明';
    for (let item of range) {
        if (toNumber(v) >= 0 && toNumber(v) <= item[0]) {
            age = item[1];
            break;
        }
    }
    return age;
}