
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import StripeService from '../../../Services/StripeService';

import config from '../../../config';

// ワークスペースの設定を行うアプリ
export default function PlanManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(true);
    let [plan, setPlan] = React.useState(0);
    const { user, setUser } = useAuthContext();
    const toast = useToast();

    // プラン変更確認ダイアログ
    let [isChangeMemberRoleModal, setChangeMemberRoleModal] = React.useState(null);

    // プラン変更処理
    const onClickSaveAccount = async (no) => {
        setLoading(true);
        const plan = {
            // ライセンスID(ワークスペースIDと共通)
            lid: space.sid,
            // ライセンスタイプ
            type: "user",
            // プラン
            plan: no,
            // URL
            api_url: api_url,
            url: success_url
        };
        const result = await StripeService.stripe(space.sid, plan);
        switch (result) {
            case 'not_changed':
                console.log('プランは変更されません');
                break;
            case 'change_at_next_month':
                console.log('来月にプランが変更されます');
                break;
        }
        setPlan(no);
    };

    const api_url = config.api;
    const success_url = config.host + "/home"

    useEffect(() => {
        const func = async () => {
            // 最新のプラン情報を得る
            const result = await StripeService.getstripe(space.sid, space.sid);
            if (result) {
                setPlan(result.plan);
                user.plan = result.plan;
                setUser(user);
            }
            setLoading(false);
        };
        func();
    }, [plan]);

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
        h={"calc(100vh - 48px)"}
        overflowX="hidden"
        overflowY="auto"
    >{loading ? <>
        loading...
    </>
        : <>
            <Flex justifyContent="center" w={'full'}>
                <Box textAlign="center" py={10} px={6}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        プラン
                    </Heading>
                    <Box p={2}>
                        <Text color={'gray.500'}>
                            現在のプラン {
                                ["Free", "Coffee", "Team", "Company"][plan]
                            }
                        </Text>
                    </Box>

                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        textAlign="center"
                        justify="center"
                        spacing={{ base: 4, lg: 4 }}
                        py={10}>

                        <PriceWrapper
                            enable={plan === 0}
                            onClick={() => {
                                setChangeMemberRoleModal({ name: "Free", plan: 0 });
                            }}>
                            <Box py={4} px={6}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Free
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="2xl" fontWeight="600">
                                        無料
                                    </Text>
                                </HStack>
                                <Text py={1}>個人向け</Text>
                                <Text py={1}>オフラインのみ</Text>
                            </Box>
                        </PriceWrapper>

                        <PriceWrapper
                            enable={plan === 1}
                            onClick={() => {
                                setChangeMemberRoleModal({ name: "Coffee", plan: 1 });
                            }}>
                            <Box py={4} px={6}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Coffee
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="2xl" fontWeight="900">
                                        200
                                    </Text>
                                    <Text fontSize="xl" fontWeight="600">
                                        円/月
                                    </Text>
                                </HStack>
                                <Text py={1}>個人向け</Text>
                                <Text py={1}>自分のみ</Text>
                            </Box>
                        </PriceWrapper>

                        <PriceWrapper
                            enable={plan === 2}
                            onClick={() => {
                                setChangeMemberRoleModal({ name: "Team", plan: 2 });
                            }}>
                            <Box py={4} px={6}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Team
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="2xl" fontWeight="900">
                                        2,000
                                    </Text>
                                    <Text fontSize="xl" fontWeight="600">
                                        円/月
                                    </Text>
                                </HStack>
                                <Text py={1}>チーム向け</Text>
                                <Text py={1}>10名まで</Text>
                            </Box>
                        </PriceWrapper>

                        <PriceWrapper
                            enable={plan === 3}
                            onClick={() => {
                                setChangeMemberRoleModal({ name: "Enterprise", plan: 3 });
                            }}>
                            <Box py={4} px={6}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Enterprise
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="2xl" fontWeight="900">
                                        10,000
                                    </Text>
                                    <Text fontSize="xl" fontWeight="600">
                                        円/月
                                    </Text>
                                </HStack>
                                <Text py={1}>チーム向け</Text>
                                <Text py={1}>50名まで</Text>
                            </Box>
                        </PriceWrapper>
                    </Stack>

                </Box>
            </Flex>
        </>}
        {PlanDialog()}
    </Box>
    );

    function PlanDialog() {
        // メンバー数チェック
        const members = Object.keys(space.members);
        let reduceMembers = 0;
        switch (isChangeMemberRoleModal ? isChangeMemberRoleModal.plan : 0) {
            case 3:
                if (members.length > 50) {
                    // 変更できない
                    reduceMembers = 50;
                }
                break;
            case 2:
                if (members.length > 10) {
                    // 変更できない
                    reduceMembers = 10;
                }
                break;
            default:
                if (members.length > 1) {
                    // 変更できない
                    reduceMembers = 1;
                }
                break;
        }
        if (reduceMembers) {
            return (
                <Modal isOpen={isChangeMemberRoleModal} onClose={() => { setChangeMemberRoleModal(null); }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{"プランを変更できません"}</ModalHeader>
                        <ModalBody>
                            {(isChangeMemberRoleModal ? isChangeMemberRoleModal.name : "") + " プランへ変更するにはメンバー数を " + reduceMembers + " 名以下にしてください。"}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost'
                                onClick={() => {
                                    setChangeMemberRoleModal(null);
                                }}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            );
        } else {
            return (
                <Modal isOpen={isChangeMemberRoleModal} onClose={() => { setChangeMemberRoleModal(null); }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{"プランを変更する"}</ModalHeader>
                        <ModalBody>
                            {"" + (isChangeMemberRoleModal ? isChangeMemberRoleModal.name : "") + " プランへ変更しますか？"}<br />
                            {"OKボタンを押すと、クレジットカード決済画面に進みます。"}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={() => { setChangeMemberRoleModal(null); }}>
                                Close
                            </Button>
                            <Button colorScheme='blue'
                                onClick={() => {
                                    onClickSaveAccount(isChangeMemberRoleModal.plan);
                                    setChangeMemberRoleModal(null);
                                }}
                            >OK</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            );
        }
    }

    function PriceWrapper({ enable, onClick, children }) {
        const gray200 = useColorModeValue('gray.200', 'gray.500');
        if (enable) {
            return (
                <Box
                    w={"200px"}
                    mb={4}
                    // shadow="base"
                    // borderWidth="1px"
                    alignSelf={{ base: 'center', lg: 'flex-start' }}
                    // borderColor={gray200}
                    borderRadius={'xl'}>
                    {children}
                </Box>
            );
        }
        return (
            <Box
                onClick={onClick}
                _hover={{ bg: '#ebedf0' }}
                _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                }}
                _focus={{
                    boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}
                w={"200px"}
                mb={4}
                shadow="base"
                borderWidth="1px"
                alignSelf={{ base: 'center', lg: 'flex-start' }}
                borderColor={gray200}
                borderRadius={'xl'}>
                {children}
            </Box>
        );
    }

    function ItemWrapper({ children }) {
        return (
            <VStack
                bg={useColorModeValue('gray.50', 'gray.700')}
                px={4}
                py={4}
                mb={1}
                borderBottomRadius={'xl'}>
                <Text fontWeight="500" align="left">
                    {children}
                </Text>
            </VStack>
        );
    }
}
