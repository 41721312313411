import React, { useState } from 'react';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Center,
} from '@chakra-ui/react';

export default function Copyright(props) {
    return (
        <>
            <Text align="center">
                <a style={{color: "green"}} target="_blank" rel="noopener noreferrer" href="/termsofuse">利用規約</a>
                {' | '}
                <a style={{color: "green"}} target="_blank" rel="noopener noreferrer" href="https://harolabo.com/privacy">プライバシーポリシー</a>
            </Text>
            <Text align="center">
                {'Copyright © '}
                <a style={{color: "green"}} target="_blank" rel="noopener noreferrer" href="https://harolabo.com/">HAROLABO LLC</a>
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </>
    );
}