
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    IconButton,
    Avatar,
    Box,
    Container,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Input,
    FormControl,
    FormLabel,
    Alert,
    AlertIcon,
    RadioGroup,
    Radio,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Textarea,
    Tooltip,
    Tabs,
    TabList,
    Tab,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useToast,
    ModalBody,
    Spinner,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiChevronLeft,
    FiFlag,
    FiX,
    FiExternalLink,
} from 'react-icons/fi';

import {
    GrFlagFill,
} from "react-icons/gr";

import { useNavigate, Navigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { useDropzone } from 'react-dropzone';

import { useAuthContext } from '../../Contexts/AuthContext';
import SignService from '../../Services/SignService';
import MonshinService from '../../Services/MonshinService';
import SettingsApp from './Settings';
import Loading from './Components/Loading';
import WordService from '../../Services/WordService';
import { Show, Hide } from '@chakra-ui/react'
import HistoryApp from './History';
import CaseService from '../../Repositories/CaseService';
import config from '../../config.js';
import { MobileNav } from './resultbar.js';
import InputArea from './Components/InputArea';

export default function ResultApp() {
    const { user, items, words, kana, license } = useAuthContext();
    const location = useLocation();
    const search = location.search;
    const defaultstate = new CaseService(location.state ? location.state.state : null, location.state ? location.state.result : null);
    const sid = user.uid;

    const inputText = localStorage.getItem("pockemon:input");
    const [description, setDescription] = React.useState(inputText);

    // 選択中の情報
    const [selectedState, setSelectedState] = React.useState(defaultstate);
    const [selected, setSelected] = React.useState(defaultstate.state);
    const [result, setResult] = React.useState(defaultstate.result);
    // console.log(defaultstate, selected);

    // const [firstLoading, setFirstLoading] = React.useState(null);
    // 
    const [maxScore, setMaxScore] = React.useState(30);

    let [isShowSidebar, setShowSidebar] = React.useState(true);
    let [isShowSetting, setShowSetting] = React.useState(false);
    let [isShowHistory, setShowHistory] = React.useState(false);
    // 選択した疾患
    const [decides, setDecides] = React.useState([]);
    // 除外リスト
    const [exclusion, setExclusion] = React.useState([]);
    let [updated, setUpdated] = React.useState(0);

    const white = useColorModeValue('white', 'gray.900');
    const gray = useColorModeValue('gray.200', 'gray.700');

    useEffect(() => {
        const output = JSON.parse(localStorage.getItem("pockemon:output"));
        selected['onset'] = items.onset[output["発症様式"]];
        selected['duration'] = items.duration[output["受診時間"]];
        selected['time_course'] = items["time_course"][output["症状推移"]];
        if (output["症状リスト"]) {
            selected['symptoms'] = output["症状リスト"].filter(v => v != selected['main']);
        }
        setSelected(selected);
    }, []);

    useEffect(() => {
        // console.log(selected)
        setResult(null);
        setUpdated(updated + 1);
        const timeoutId = setTimeout(() => {
            if (selected) {
                updateResult(selected, true);
            }
        }, 800);
        return () => clearTimeout(timeoutId);
    }, [JSON.stringify(selected)]);

    if (!user) {
        return (<Navigate to="/signin" />);
    }

    if (Object.keys(words).length === 0) {
        return (<Loading />);
    }

    if (isShowSetting) {
        return (<SettingsApp
            // space={space}
            setShowSetting={setShowSetting} />);
    }

    if (isShowHistory) {
        return (<HistoryApp
            // space={space}
            // select={(v) => {
            //     setDecides(v.differential);
            //     const selected = {
            //         'id': v.id,
            //         'main': v.main,
            //         'age': v.age,
            //         'ageId': convertAge(v.age),
            //         'gender': v.gender,
            //         'differentials': v.differential,
            //         'symptoms': [],
            //     };
            //     const ignores = ["男性", "女性", "性別不明",
            //         "年齢不明", "乳児", "幼児", "小児", "若年", "中年", "高齢", "超高齢"];
            //     const onset = reverse(items.onset);
            //     const duration = reverse(items.duration);
            //     const time_course = reverse(items.time_course);
            //     const repeat = reverse(items.repeat);
            //     for (const item of v.positive) {
            //         if (item === v.main || ignores.includes(item)) {
            //             ;
            //         } else if (Object.keys(onset).includes(item)) {
            //             selected['onset'] = onset[item];
            //         } else if (Object.keys(duration).includes(item)) {
            //             selected['duration'] = duration[item];
            //         } else if (Object.keys(time_course).includes(item)) {
            //             selected['time_course'] = time_course[item];
            //         } else if (Object.keys(repeat).includes(item)) {
            //             selected['repeat'] = repeat[item];
            //         } else {
            //             console.log(item);
            //             selected['symptoms'].push(item);
            //         }
            //     }

            //     // IDは元のまま
            //     const state = new CaseService({
            //         main: selected.main,
            //         age: selected.age,
            //         gender: selected.gender,
            //         differentials: selected.differentials,
            //         symptoms: selected.symptoms,
            //         onset: selected.onset,
            //         duration: selected.duration,
            //         time_course: selected.time_course,
            //         repeat: selected.repeat,
            //     });

            //     setSelectedState(state);
            //     setSelected(state.state);
            //     updateResult(state.state);
            // }}
            setShowHistory={setShowHistory} />);
    }

    return (
        <>
            <Box minH="100vh">
                <MobileNav
                    selectedState={selectedState}
                    setSelectedState={(selectedState) => {
                        setSelectedState(selectedState);
                        setSelected(selectedState.state);
                    }}
                    selected={selected}
                    setSelected={(s) => {
                        setSelected(s);
                        setUpdated(updated + 1);
                    }}
                    isShowSidebar={isShowSidebar}
                    setShowSetting={setShowSetting}
                    setShowHistory={setShowHistory}
                />
                <Show above='md'>
                    <Flex
                        bg={white}
                        h={"calc(100vh - 48px)"}
                        overflowY="hidden"
                    >
                        <Box
                            w={'full'}
                            bg={white}
                            borderRight="1px"
                            borderRightColor={gray}
                            p="4"
                            h={"calc(100vh - 48px)"}
                            overflowY="auto"
                        >
                            <SidebarContent
                                age0={selected["age"]}
                                gender0={selected["sex"]}
                                selected={selected}
                                setSelected={(s) => {
                                    setSelected(s);
                                    setUpdated(updated + 1);
                                }}
                                result={result}
                                isShowSidebar={isShowSidebar}
                                text={""}
                                description={description}
                                setDescription={setDescription}
                            />
                        </Box>
                        <Box
                            w={'full'}
                            h={"calc(100vh - 48px)"}
                            overflowY={'auto'}
                        >
                            <Result
                                key={updated}
                                selected={selected}
                                result={result}
                                max={maxScore}
                                exclusion={exclusion}
                                setExclusion={setExclusion}
                                decides={decides}
                                setDecides={setDecides}
                                selectedState={selectedState}
                                setSelectedState={(selectedState) => {
                                    setSelectedState(selectedState);
                                    updateDDx(selectedState);
                                }}
                            />
                        </Box>
                    </Flex>
                </Show>
                <Hide above='md'>
                    {true ? <>
                        <Box
                            w={'full'}
                            bg={white}
                            borderRight="1px"
                            borderRightColor={gray}
                            p="4"
                        >
                            <SidebarContent
                                selected={selected}
                                setSelected={(s) => {
                                    setSelected(s);
                                    setUpdated(updated + 1);
                                }}
                                result={result}
                                isShowSidebar={isShowSidebar}
                                text={""}
                                description={description}
                                setDescription={setDescription}
                            />
                        </Box>
                        <Box
                            w={'full'}
                        >
                            <Result
                                key={updated}
                                selected={selected}
                                result={result}
                                max={maxScore}
                                exclusion={exclusion}
                                setExclusion={setExclusion}
                                decides={decides}
                                setDecides={setDecides}
                                selectedState={selectedState}
                                setSelectedState={(selectedState) => {
                                    setSelectedState(selectedState);
                                    updateDDx(selectedState);
                                }}
                            />
                        </Box>
                    </> : <Center>
                        <Box p={4}>
                            <Spinner />
                        </Box>
                    </Center>}
                </Hide>
            </Box>
        </>
    );

    // 変更があるたびに結果を更新する
    function updateResult(selected, refresh, newState) {
        if (!newState) {
            newState = selectedState;
        }
        let caseId = newState.id;

        // console.log(selected)
        // 疾患を検索する
        const sid = "_public";

        let list = [selected['main']];
        if (selected['ageId'] && selected['ageId'] !== "年齢不明") {
            list.push(selected['ageId']);
        }
        if (selected['sex'] && selected['sex'] !== "性別不明") {
            list.push(selected['sex']);
        }
        if (selected['duration']) {
            list.push(selected['duration']);
        }
        if (selected['time_course']) {
            list.push(selected['time_course']);
        }
        if (selected['onset']) {
            list.push(selected['onset']);
        }
        if (selected['repeat']) {
            list.push(selected['repeat']);
        }
        if (selected['symptoms']) {
            list = list.concat(selected['symptoms']);
        }

        let ddxs = newState.result.ddxs;
        let exclusions = newState.result.exclusions;

        MonshinService.diag(user, sid, caseId, selected['main'], selected["age"], selected["sex"], list,
            ddxs, exclusions, description,
            (result) => {
                if (!refresh) {
                    return;
                }

                // 疾患リストを更新
                setResult(result);

                // 最大のスコアをカウント
                let total = 0;
                let count = 0;
                let data = [];
                if (result) {
                    for (const item of result.similars) {
                        if (item.score > 0) {
                            data.push(item.score);
                            total += item.score;
                            count++;
                        }
                    }
                }
                // // 分散
                // const ave = total / count;
                // let a = 0;
                // for (const v of data) {
                //     a += (ave - v) * (ave - v);
                // }
                // let b = a / count;
                setMaxScore(total);

                setUpdated(updated + 2);
            });
    }

    function updateDDx(selectedState) {
        updateResult(selected, false, selectedState);
        // let list = [];
        // if (selected['duration']) {
        //     list.push(selected['duration']);
        // }
        // if (selected['time_course']) {
        //     list.push(selected['time_course']);
        // }
        // if (selected['onset']) {
        //     list.push(selected['onset']);
        // }
        // if (selected['repeat']) {
        //     list.push(selected['repeat']);
        // }
        // if (selected['symptoms']) {
        //     list = list.concat(selected['symptoms']);
        // }
        // const results = [];
        // console.log(result)
        // for (const item of result.similars) {
        //     results.push(item["disease"]);
        // }
        // const data = {
        //     id: selectedState.id,
        //     main: selectedState.state.main,
        //     age: selectedState.state.age,
        //     gender: selectedState.state.sex,
        //     positives: list,
        //     results: results,
        //     ddxs: selectedState.result.ddxs,
        //     exclusions: selectedState.result.exclusions
        // };
        // MonshinService.saveCase(user, sid, data);
    }
}

// 結果
const Result = ({ key, result, max, exclusion, setExclusion, decides, setDecides, selectedState, setSelectedState, ...rest }) => {
    const search = useLocation().search;
    const { user, items, words, kana, license } = useAuthContext();
    const query2 = new URLSearchParams(search);
    const sid = user.uid;

    // タブ番号
    const [index, setIndex] = React.useState(0);

    // 表示中のリスト
    const [similars, setSimilars] = React.useState(null);

    // 各タブのリスト
    let [results, setResults] = React.useState({
        result0: [],
        result1: [],
    });

    // 追加疾患名
    const [value, setValue] = React.useState("");

    if (!similars) {
        updateSimilars(result && result.similars, index, exclusion, decides);
    }

    // 疾患リストをフィルター条件に合わせて設定する
    function updateSimilars(similars, n, exclusion, decides) {
        results = {
            result0: [],
            result1: [],
        };
        const result0 = [];
        const result1 = [];
        let result = [];
        if (similars) {
            for (const data of similars) {
                if (exclusion.includes(data.disease)) {
                    ;
                } else {
                    result0.push(data);
                    // if (data.score >= 0) {
                    //     result0.push(data);
                    // }
                    if (data.urgency === 3) {
                        result1.push(data);
                    }
                    switch (n) {
                        case 0:
                            result.push(data);
                            // if (data.score >= 0) {
                            //     result.push(data);
                            // }
                            break;
                        case 1:
                            if (data.urgency === 3) {
                                result.push(data);
                            }
                            break;
                    }
                }
            }
        }
        setResults({
            result0: result0.length,
            result1: result1.length,
        });
        if (n === 3) {
            for (const item of exclusion) {
                result.push({
                    disease: item,
                });
            }
        } else if (n === 2) {
            for (const item of decides) {
                result.push({
                    disease: item,
                });
            }
        }
        setSimilars(result);
        setIndex(n);
    }

    // 鑑別診断結果に入れる/外す
    function onClickResult(data) {
        if (index !== 3) {
            if (decides.includes(data.disease)) {
                decides = decides.filter(v => v !== data.disease);
            } else {
                decides.push(data.disease);
            }
            setDecides(decides);
            updateSimilars(result.similars, index, exclusion, decides);

            selectedState.result.ddxs = decides;
            selectedState.updateResult({
                ddxs: decides
            });
            setSelectedState(selectedState);
            // MonshinService.saveCase(sid, selectedState.state);
        }
    }

    // 除外に入れる/外す
    function onClickOut(data, isset) {
        if (isset) {
            if (exclusion.includes(data.disease)) {
                ;
            } else {
                exclusion.push(data.disease);
            }
        } else {
            if (exclusion.includes(data.disease)) {
                exclusion = exclusion.filter(v => v !== data.disease);
            } else {
                ;
            }
        }
        setExclusion(exclusion);
        updateSimilars(result.similars, index, exclusion, decides);

        selectedState.result.exclusions = exclusion;
        selectedState.updateResult({
            exclusions: exclusion
        });
        setSelectedState(selectedState);
        // MonshinService.saveCase(sid, selectedState.state);
    }

    const dev = (config.dev && license && license.role === "dev");

    return (
        <Box p="2" key={key}>
            <Tabs px={2} variant='soft-rounded' colorScheme='blue'
                index={index}
                onChange={(e) => {
                    if (result) {
                        updateSimilars(result.similars, e, exclusion, decides);
                    }
                }}>
                <TabList>
                    <Tab key={"list"} value={"list"}>{"上位(" + results.result0 + ")"}</Tab>
                    <Tab key={"red"} value={"red"}>{"レッドフラッグ(" + results.result1 + ")"}</Tab>
                    <Tab key={"result"} value={"result"}>{"鑑別診断結果(" + decides.length + ")"}</Tab>
                    <Tab key={"exclusive"} value={"exclusive"}>{"除外(" + exclusion.length + ")"}</Tab>
                </TabList>
            </Tabs>

            <TableContainer overflowX={'hidden'}>
                <Table style={{ tableLayout: 'fixed' }}>
                    <Thead>
                        <Tr>
                            <Th w={dev && index < 2 ? '60%' : '100%'}>{"疾患名"}</Th>
                            {dev && index < 2 ? <Th w={'20%'}>スコア(dev)</Th> : null}
                            {dev && index < 2 ? <Th w={'20%'}>有病率(dev)</Th> : null}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {index === 2 ?
                            <Box m={4}>
                                <Input variant='outline' placeholder='候補にない疾患名を追加'
                                    value={value}
                                    onChange={(event) => {
                                        setValue(event.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && value.length > 0) {
                                            onClickResult({
                                                disease: value + "*"
                                            });
                                            setValue("");
                                        }
                                    }}
                                />
                            </Box> : null}
                        {similars && similars.map((data, i) =>
                            <Tr>
                                <Td
                                    bg={decides.includes(data.disease) ? "#C6F6D5" : "white"}
                                    _hover={decides.includes(data.disease) ? { bg: '#C6F6D5' } : { bg: 'whitesmoke' }}
                                >
                                    <Flex>
                                        {data.urgency === 3 ? <Icon mr={2} as={GrFlagFill} color={"red"} /> : null}
                                        <Tooltip label={decides.includes(data.disease) ? '外す' : '選択'}>
                                            <Text
                                                onClick={(e) => {
                                                    onClickResult(data);
                                                }}
                                                fontSize="1.2em"
                                                color={index >= 2 || data.score > 0 ? "black" : "gray"}>
                                                {/* <Tooltip label={<Text>aaa</Text>}> */}
                                                {data.disease}
                                                {/* </Tooltip> */}
                                            </Text>
                                        </Tooltip>
                                        <Tooltip label='Webで検索'>
                                            <a href={"https://www.google.com/search?q=" + data.disease} target="_blank">
                                                <Icon ml={4} as={FiExternalLink} color={"gray"} />
                                            </a>
                                        </Tooltip>
                                        {index < 2 ? <Tooltip label='除外'>
                                            <div>
                                                <Icon ml={2} as={FiX} color={"gray"} onClick={(e) => {
                                                    onClickOut(data, true);
                                                }} />
                                            </div>
                                        </Tooltip> :
                                            index === 3 ? <Tooltip label='除外から外す'>
                                                <div>
                                                    <Icon ml={2} as={FiX} color={"gray"} onClick={(e) => {
                                                        onClickOut(data, false);
                                                    }} />
                                                </div>
                                            </Tooltip> : null}
                                    </Flex>
                                    {index < 2 ?
                                        <div style={{ marginTop: '4px', width: '' + (data.score > 0 ? data.score / max * 100 : 0) + '%', height: '4px', backgroundColor: "#adc6ff" }} />
                                        : null}
                                </Td>
                                {dev ? <><Td color={data.score > 0 ? "black" : "gray"}>
                                    {data.score ? data.score.toFixed(2) : 0}
                                </Td><Td>{data.rate}</Td></> : null}
                            </Tr>)}
                    </Tbody>
                </Table>
                {result ? null : <Center>
                    <Box p={4}>
                        <Spinner />
                    </Box>
                </Center>}
            </TableContainer>
        </Box>
    );
};

const FItem = ({ label, children, ...rest }) => {
    return (
        <FormControl marginBottom={4}>
            <FormLabel color="green" fontWeight={'bold'}>{label}</FormLabel>
            {children}
        </FormControl>
    );
};

// サイドバー
const SidebarContent = ({ selected, setSelected, result, text, description, setDescription, ...rest }) => {
    const { user, items, kana, words, license } = useAuthContext();
    const sid = user.uid;

    const output = JSON.parse(localStorage.getItem("pockemon:output"));
    let gpt = [];
    if (output) {
        gpt.push("疾患候補：");
        gpt.push("　" + getValue(output, "疾患候補"));
        gpt.push("理由：");
        gpt.push("　" + getValue(output, "理由"));
    }

    function getValue(output, key) {
        if (Object.keys(output).includes(key)) {
            return output[key];
        }
        return "";
    }

    const [onset, setOnset] = React.useState(selected['onset']);
    const [duration, setDuration] = React.useState(selected['duration']);
    const [timeCourse, setTimeCourse] = React.useState(selected['time_course']);
    const [repeat, setRepeat] = React.useState(null);
    const [value, setValue] = React.useState(text);
    const [recommends, setRecommends] = React.useState([]);
    const [subSelected, setSubSelected] = React.useState(null);
    const [subRecommends, setSubRecommends] = React.useState([]);
    const [subModal, setSubModal] = React.useState(false);
    let [updated, setUpdated] = React.useState(0);

    const symptoms = selected['symptoms'];

    // function setSymptoms(s) {
    //     selected['symptoms'] = s;
    //     setSelected(selected);
    // }

    let evaluates = [];
    if (result && result.evaluates) {
        for (const item of result.evaluates) {
            if (!evaluates.includes(item[1]) &&
                !symptoms.includes(item[1]) &&
                selected['main'] !== item[1] &&
                item[2] === 'symptoms') {
                evaluates.push(item[1]);
            }
        }
    }

    const dev = (config.dev || (license && license.role === "dev"));

    return (
        <>
            {dev ?
                <FItem label="ノート(dev)">
                    <InputArea
                        value={description}
                        placeholder='ノートを記録できます'
                        onChange={(event) => {
                            setDescription(event.target.value);
                            localStorage.setItem("pockemon:input", event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                    />
                    <div style={{ padding: "4px" }} />
                    {/* {autoCandidates.length > 0 ? renderRadioSelect1(autoCandidates, "", '', ()=>{}) : null} */}
                    <div style={{ padding: "4px" }} />
                    <Alert status='warning'>
                        <AlertIcon />
                        <Text fontSize={"xs"}>
                            患者の個人情報を入力しないようにしてください。患者の個人情報を入力される際には、法令上、当該患者から事前の同意を取得する必要があります。
                        </Text>
                    </Alert>
                    <Box pt={4}>
                        <Text fontSize={"xs"} fontWeight={"bold"}>{"AIの見解"}</Text>
                        {gpt.map((v, i) => { return (<Text fontSize={"xs"}>{v}</Text>) })}
                    </Box>
                </FItem>
                : null}
            <FItem label="発症様式">
                {renderRadioSelectLang(items.onset, "", selected['onset'], (v) => {
                    selected['onset'] = items.onset[v];
                    setSelected(selected);
                })}
            </FItem>
            <FItem label="発症から受診までの期間">
                {renderRadioSelectLang2(items.duration, "", selected['duration'], (v) => {
                    selected['duration'] = items.duration[v];
                    setSelected(selected);
                })}
            </FItem>
            <FItem label="発症から受診までの症状推移">
                {renderRadioSelectLang(items.time_course, "", selected['time_course'], (v) => {
                    selected['time_course'] = items["time_course"][v];
                    setSelected(selected);
                })}
            </FItem>
            <FItem label="間欠期がある/再増悪した">
                {renderRadioSelectLang(items.repeat, "", selected['repeat'], (v) => {
                    selected['repeat'] = items.repeat[v];
                    setSelected(selected);
                })}
            </FItem>
            <FItem label="他の症状（随伴症状）※削除するには症状をクリックします">
                {symptoms.map((v, i) => {
                    return (<Button m={1} color='#276749' backgroundColor='#C6F6D5'
                        onClick={() => {
                            const s = symptoms.filter(function (x) { return x !== v });
                            // setSymptoms(s);
                            selected['symptoms'] = s;
                            setSelected(selected);
                        }}
                    >{v}</Button>);
                })}
                {symptoms.length > 0 ? <Box color={"gray"} fontSize={'0.9em'} p={2} paddingBottom={4}>

                </Box> : <Box p={2} color={"gray"} fontWeight={"bold"} paddingBottom={4} paddingLeft={4}>
                    選択されていません
                </Box>}
                <Box p={2}>
                    <Input variant='outline' placeholder='他の症状を追加（入力後Enterキーを押すとさらに候補を表示）'
                        value={value}
                        onChange={(event) => {
                            setValue(event.target.value);
                            if (event.target.value.length > 0) {
                                analyzeText(event.target.value);
                            } else {
                                setRecommends([]);
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                recommendSynonyms(value);
                            }
                        }}
                    />
                    <Box
                        mt={1}
                        left={200}
                        zIndex={999}
                        textAlign={'left'}>
                        {(value.length === 0 && recommends.length === 0 ? evaluates : recommends).map((v, i) => {
                            return (<Button m={1}
                                onClick={() => {
                                    onSearch(v);
                                }}
                            >{v}</Button>);
                        })}
                    </Box>
                    <Box color={"gray"} fontSize={'0.9em'} p={2} paddingBottom={4}>
                        ※データベースにない症状も入力・選択して記録できます（データベースにない症状は検索結果には影響しません）
                    </Box>
                </Box>
            </FItem>

            <Modal isOpen={subModal} onClose={() => { setSubSelected(null); setSubModal(false); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>具体的な症状を選んでください</ModalHeader>
                    <ModalBody>
                        <Box p={4}>{subRecommends.map((v, i) => {
                            return (<Button m={1}
                                onClick={() => {
                                    setSubModal(false);
                                    onSearch(v);
                                }}
                            >{v}</Button>);
                        })}</Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )

    function renderRadioSelectLang(list, placeholder, defValue, handleChange) {
        if (!list) {
            return <div />;
        }
        let index = 0;
        for (const key of sortKeys(list)) {
            if (list[key] === defValue) {
                break;
            }
            index++;
        }
        return (
            <Tabs variant='soft-rounded' colorScheme='green'
                index={index}
                onChange={(e) => {
                    const key = sortKeys(list)[e];
                    handleChange(key);
                }}>
                <TabList>
                    {sortKeys(list).map((data, index) => {
                        return <Tab key={index} value={data}>{data}</Tab>;
                    })}
                    <Tab key={"不明"} value={"不明"}>{"不明"}</Tab>
                </TabList>
            </Tabs>
        )
    }

    function renderRadioSelectLang2(list, placeholder, defValue, handleChange) {
        if (!list) {
            return <div />;
        }
        const list1 = [];
        const list2 = [];
        let count = 0;
        let index = Object.keys(list).length;
        for (const key of sortKeys(list)) {
            if (list[key] === defValue) {
                index = count;
            }
            if (count < 3) {
                list1.push(key);
            } else {
                list2.push(key);
            }
            count++;
        }
        return (
            <>
                <Tabs variant='soft-rounded' colorScheme='green'
                    index={index < 3 ? index : -1}
                    onChange={(e) => {
                        const key = sortKeys(list)[e];
                        handleChange(key);
                    }}>
                    <TabList>
                        {list1.map((data, index) => {
                            return <Tab key={index} value={data}>{data}</Tab>;
                        })}
                    </TabList>
                </Tabs>
                <Tabs variant='soft-rounded' colorScheme='green'
                    index={index >= 3 ? index - 3 : -1}
                    onChange={(e) => {
                        const key = sortKeys(list)[e + 3];
                        handleChange(key);
                    }}>
                    <TabList>
                        {list2.map((data, index) => {
                            return <Tab key={index} value={data}>{data}</Tab>;
                        })}
                        <Tab key={"不明"} value={"不明"}>{"不明"}</Tab>
                    </TabList>
                </Tabs>
            </>
        )
    }

    function sortKeys(list) {
        const key = Object.keys(list)[0];
        if (key === "突然") {
            return [
                "突然",
                "急性",
                "亜急性",
                "緩徐",
            ];
        }
        else if (key === "1日以上7日未満") {
            return [
                "24時間未満",
                "1日以上7日未満",
                "1週以上4週未満",
                "1ヶ月以上12ヶ月未満",
                "1年以上",
            ];
        }
        else if (key === "横ばい") {
            return [
                "急激な悪化",
                "悪化する",
                "横ばい",
                "改善する",
            ];
        }
        return Object.keys(list);
    }

    function onSearch(v) {
        if (subSelected !== v) {
            const structure = items.structure;
            for (const key of Object.keys(structure)) {
                if (key === v) {
                    setSubSelected(v);
                    setSubRecommends(structure[key]);
                    setSubModal(true);
                    return;
                }
            }
        }

        if (!symptoms.includes(v)) {
            symptoms.push(v);
            // setSymptoms(symptoms);
            selected['symptoms'] = symptoms;
            setSelected(selected);
            setSubSelected(null);
            setValue('');
        }
    }

    function analyzeText(t) {
        setRecommends(WordService.analyzeText(items, kana, t));
    }

    async function recommendSynonyms(t) {
        const labels = [];
        const result = await MonshinService.getSynonyms(sid, t);
        console.log(result)
        if (Object.keys(result).length === 1) {
            labels.push(t + "*");
            const synonyms = result[t];
            for (const synonym of synonyms) {
                if (!labels.includes(synonym.label)) {
                    labels.push(synonym.label);
                }
            }
        }
        setRecommends(labels);
    }
};
