
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    IconButton,
    Alert,
    AlertIcon,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Textarea,
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import ContractService from '../../../Services/ContractService';
import MonshinService from '../../../Services/MonshinService';
import uuid from 'react-uuid';
import Loading from '../Components/Loading';
import Colors from '../../../Constants/Colors';
import Waiting from '../Components/Waiting';

// ワークスペースの設定を行うアプリ
export default function ApiManager({
    space, children,
}) {
    const colors = new Colors();
    const gray700 = colors.gray700;
    const white = colors.white;
    const { user, state } = useAuthContext();
    const toast = useToast();

    let [loading, setLoading] = React.useState(false);
    let [apiId, setApiId] = React.useState(null);
    let [key1, setKey1] = React.useState("");
    let [key2, setKey2] = React.useState("");
    let [name, setName] = React.useState("");
    let [result, setResult] = React.useState("");

    const sid = user.uid;
    const tid = sid + "-apitest";

    useEffect(() => {
        const func = async () => {
            setLoading(true);
            // 最新のプラン情報を得る
            const result = await ContractService.getContract(sid);
            if (result && result.secrets.length === 2) {
                setKey1(result.secrets[0]);
                setKey2(result.secrets[1]);
                console.log(result);
            }
            setApiId(user.uid);
            setLoading(false);

            // // ISDB get
            // MonshinService.getISDB();
        };
        func();
    }, []);

    function noApi() {
        return (<>
            <Alert status='info'>
                <AlertIcon />
                APIを利用するには、API契約が必要です
            </Alert>
        </>);
    }

    return (
        <Box
            color={gray700}
            bg={white}
            w={'calc(100vw - 200px)'}
            h={"calc(100vh - 48px)"}
            overflowY="auto"
            align="center"
            p={8}
        >
            <Box
                w={"full"}
                maxW={"800px"}
                align="left"
            >
                <VStack spacing={5}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        APIの設定
                    </Heading>

                    {loading ? <Waiting />
                        : <>
                            <TableContainer overflowX="unset" overflowY="unset"
                                width={"100%"}>
                                <Table variant='simple' size='md'>
                                    <Thead position="sticky" top={0} zIndex="docked" bg="white">
                                        <Tr>
                                            <Th>API ID</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{apiId}</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            {key1.length === 0 ? noApi() : <TableContainer overflowX="unset" overflowY="unset"
                                width={"100%"}>
                                <Table variant='simple' size='md'>
                                    <Thead position="sticky" top={0} zIndex="docked" bg="white">
                                        <Tr>
                                            <Th>APIキー</Th>
                                            <Th></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{key1}</Td>
                                            <Td><Button onClick={async () => {
                                                const key = uuid().replace(/-/g, '');
                                                if (await ContractService.updateApiKeys(sid, key + "," + key2)) {
                                                    setKey1(key);
                                                }
                                            }}>キーの更新</Button></Td>
                                        </Tr>
                                        <Tr>
                                            <Td>{key2}</Td>
                                            <Td><Button onClick={async () => {
                                                const key = uuid().replace(/-/g, '');
                                                if (await ContractService.updateApiKeys(sid, key1 + "," + key)) {
                                                    setKey2(key);
                                                }
                                            }}>キーの更新</Button></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>}

                            {/* <Box
                                p={6}
                                width={"100%"}
                                align="left">
                                {"APIのテスト"}
                            </Box>
                            <Tabs>
                                <TabList>
                                    <Tab>症状所見一覧取得</Tab>
                                    <Tab>症状所見推論(β)</Tab>
                                    <Tab>疾患候補推論</Tab>
                                    <Tab>症例一覧取得</Tab>
                                    <Tab>症例保存</Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        <p>症状所見一覧取得</p>
                                        {"GET /monshin/v1/symptomsall"}
                                        <Button onClick={async () => {
                                            const res = await ContractService.getApi(apiId, key1, "/monshin/v1/symptomsall?tenant=" + tid);
                                            console.log(res);
                                            setResult(JSON.stringify(res.data, null, "\t"));
                                        }}>実行</Button>
                                        <Textarea
                                            minH={"600px"}
                                            value={result}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <p>症状所見推論(β)</p>
                                        {"POST /monshin/v1/synonyms"}
                                        <Button onClick={async () => {
                                            const res = await ContractService.postApi(apiId, key1, "/monshin/v1/synonyms?tenant=" + tid, {
                                                text: "おなかが痛い"
                                            });
                                            console.log(res);
                                            setResult(JSON.stringify(res.data, null, "\t"));
                                        }}>実行</Button>
                                        <Textarea
                                            value={"おなかが痛い"}
                                        />
                                        <Textarea
                                            minH={"600px"}
                                            value={result}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <p>疾患候補推論</p>
                                        {"POST /monshin/v1/diagnose"}
                                        <Button onClick={async () => {
                                            const res = await ContractService.postApi(apiId, key1, "/monshin/v1/diagnose?tenant=" + tid, {
                                                positives: ["発熱", "男性"]
                                            });
                                            console.log(res);
                                            setResult(JSON.stringify(res.data, null, "\t"));
                                        }}>実行</Button>
                                        <Textarea
                                            value={"発熱"}
                                        />
                                        <Textarea
                                            minH={"600px"}
                                            value={result}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <p>症例一覧取得</p>
                                        {"GET /monshin/v1/samples"}
                                        <Button onClick={async () => {
                                            const res = await ContractService.getApi(apiId, key1, "/monshin/v1/samples?tenant=" + tid);
                                            console.log(res);
                                            setResult(JSON.stringify(res.data, null, "\t"));
                                        }}>実行</Button>
                                        <Textarea
                                            minH={"600px"}
                                            value={result}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <p>症例保存</p>
                                        {"POST /monshin/v1/sample"}
                                        <Button onClick={async () => {
                                            const res = await ContractService.postApi(apiId, key1, "/monshin/v1/sample?tenant=" + tid, {

                                            });
                                            console.log(res);
                                        }}>症例保存</Button>
                                    </TabPanel>

                                </TabPanels>
                            </Tabs> */}
                        </>}
                </VStack>
            </Box>
        </Box>
    );
}