
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';
import MonshinService from '../../../Services/MonshinService';
import InputArea from '../Components/InputArea';

// 現在のISDBにおける精度を示す。
// コモンディジーズがそれぞれの症例の症状を選択した場合に、何位に位置するかを示す。
export default function SampleManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(false);
    let { db, setDb, user } = useAuthContext();
    const toast = useToast();

    let [status, setStatus] = React.useState("noload");
    let [diseases, setDeseases] = React.useState([]);
    let [searchText, setSearchText] = React.useState("");
    let [disease, setDesease] = React.useState(null);
    let [issort, setIsSort] = React.useState(false);

    const [description, setDescription] = React.useState("");
    const [result, setResult] = React.useState("");
    const [recommends, setRecommends] = React.useState([]);

    useEffect(() => {
        if (db) {
            const diseases = MonshinService.buildIsdb(db);
            setDb(db);
            setDeseases(diseases);
            setStatus("load");
        }
    }, []);

    // サーバーからISDBをロードする
    async function load() {
        setLoading(true);
        db = await MonshinService.getISDB();
        const diseases = MonshinService.buildIsdb(db);
        setDb(db);
        setDeseases(diseases);
        setLoading(false);
    }

    let n = 0;

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
        h={"calc(100vh - 48px)"}
    >
        <Flex>
            <Box
                w={"400px"}
                h={"calc(100vh - 48px)"}
                overflowX="auto"
                overflowY="auto"
            >
                <Box p={4}>
                    <Flex>
                        <Button onClick={load}>load</Button>
                        <Text>{status}</Text>
                    </Flex>
                    <Input
                        variant='outline'
                        placeholder='検索...'
                        onChange={(event) => {
                            setSearchText(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                    />
                </Box>
                {loading ? <>
                    loading...
                </>
                    : <TableContainer>
                        <Table size={"sm"}>
                            <Thead>
                                <Tr>
                                    <Th>有病率</Th>
                                    <Th>疾患</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {diseases.map((d, i) => {
                                    if (n > 20) {
                                        return null;
                                    }
                                    const name = d.name;
                                    if (searchText.length > 0) {
                                        if (name.indexOf(searchText) === -1) {
                                            return null;
                                        }
                                    }
                                    n++;
                                    return (
                                        <Tr onClick={() => { setDesease(name); }}
                                            bgColor={disease === name ? "#C4F1F9" : "initial"}
                                        >
                                            <Td>{d.rate}</Td>
                                            <Td>{name}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
            </Box>
            <Box
                w={"full"}
                h={"calc(100vh - 48px)"}
                overflowX="auto"
                overflowY="auto"
            >
                {renderWeight()}
            </Box>
        </Flex>
    </Box>);

    function renderWeight() {
        const symptoms = {};
        let symptomlist = [];
        if (disease) {
            for (const item of db.isdb) {
                if (item.name === disease) {
                    symptoms[item.type] = item.symptoms;
                    if (item.type === "W") {
                        symptomlist = item.symptoms;
                    }
                }
            }
            symptomlist.sort(function (a, b) {
                const as = getCategoryNo(a.category);
                const bs = getCategoryNo(b.category);
                if (as > bs) return -1;
                if (as < bs) return 1;
                return 0;
            });
        }

        return (disease ?
            <>
                <Box p={4}>
                    {disease}
                </Box>
                <Box>
                    <InputArea
                        loading={loading || recommends.length > 0}
                        value={description}
                        placeholder='文章を入力してください'
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                // getResult(description);
                            }
                        }}
                    />
                    {recommends.length === 0 ? <Button
                        mt={4}
                        isDisabled={loading || description.length === 0}
                        onClick={() => {
                            getResult(description);
                        }}
                    >{"確定"}</Button> : <Button
                        mt={4}
                        onClick={() => {
                            setDescription("");
                            setRecommends([]);
                        }}
                    >{"入力し直す"}</Button>}
                </Box>
                <Box>
                    {recommends.map((v, i) => {
                        return (<Button m={1}
                            onClick={() => {
                            }}
                        >{v}</Button>);
                    })}
                </Box>
            </> : null);
    }

    function getCategoryNo(c) {
        if (!c) return "：";
        if (c === "keyword") return "・";
        return c.toUpperCase();
    }

    async function getResult(t) {
        const sid = user.uid;
        // setLoading(true);

        const result = await MonshinService.getResult(sid, t);
        console.log(result);
        const text = result.choices[0].message.content;
        setResult(text);

        const output = {};
        const lines = text.split("\n");
        let recommends = [];
        const regex = new RegExp(`[ ,、]`, 'g');
        for (const line of lines) {
            const item = line.split(":");
            const key = item[0].trim();
            const data = item[1].split(regex);
            switch (key) {
                case "年齢":
                case "性別":
                case "症状":
                case "発症様式":
                case "受診時間":
                    recommends = recommends.concat(data);
                    break;
            }
        }
        recommends = recommends.filter(str => str !== '');
        setRecommends(recommends);
        console.log(recommends);
        // localStorage.setItem("pockemon:output", JSON.stringify(output));

        // setLoading(false);
    }

    function getWeight(symptoms, t, name) {
        if (symptoms[t]) {
            for (const symptom of symptoms[t]) {
                if (symptom.name === name) {
                    return symptom.lsp;
                }
            }
        }
        return "";
    }

    function updateWeight(disease, type, d, w) {
        const name = d["name"];
        const category = d["category"];

        // isdbのデータを変更してsetupする
        const isdb = db.isdb;
        let match = false;
        for (const item of isdb) {
            if (item.name === disease && item.type === type) {
                for (const symptom of item.symptoms) {
                    if (symptom.name === name) {
                        symptom.lsp = w !== "" ? Number(w) : w;
                        match = true;
                        break;
                    }
                }
                // typeの症状がない場合は追加
                if (!match) {
                    item.symptoms.push({
                        category: category,
                        name: name,
                        lsp: w !== "" ? Number(w) : w
                    });
                    match = true;
                    console.log("match symptom ", name);
                }
                break;
            }
        }
        // typeがない場合は追加
        if (!match) {
            isdb.push({
                name: disease,
                type: type,
                symptoms: [{
                    category: category,
                    name: name,
                    lsp: Number(w)
                }]
            });
            console.log("match type ", type);
        }
        const diseases = MonshinService.buildIsdb(db);
        setDb(db);
        setDeseases(diseases);
        setStatus("update");
    }

    // 推論
    function diag(disease, symptoms) {
        // 指定した症状リスト
    }
}
