import React, { useRef, useEffect } from 'react';
import video_mp4 from "./loading.mp4"
import PuffLoader from "react-spinners/PuffLoader";

export default function Waiting(props) {
    const style = {
        backgroundColor: "white",
        width: "100%", height: "100%", display: "flex",
        justifyContent: "center", alignItems: "center"
        // justifyContent: "end", alignItems: "end"
    };

    function loader() {
        return (
            <div style={{ paddingBottom: "120px" }}>
                <video width={150} autoPlay loop muted playsInline src={video_mp4} type="video/mp4" />
                {/* <BarLoader color={'#36D7B7'} css={css`
                    display: block;
                    margin: 0 auto;
                    border-color: red;
                    width: 200px;
                    margin-top: -16px;
                `} /> */}
                {/* <h4 style={{ color: "gray" }}>{props.message}</h4> */}
            </div>
        );
    }

    return (
        <div style={style}>
            {/* {loader()} */}
            <PuffLoader color="#36d7b7" />
        </div>);
}