
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { MySheet } from '../Components/mysheet';
import MonshinService from '../../../Services/MonshinService';
import { useNavigate } from 'react-router-dom';
import SignService from '../../../Services/SignService';
import TimeService from '../../../Services/TimeService';
import CaseService from '../../../Repositories/CaseService';
import Loading from '../Components/Loading';
import { CSVLink, CSVDownload } from "react-csv";

function reverse(obj) {
    const result = {};
    for (const key of Object.keys(obj)) {
        const val = obj[key];
        result[val] = key;
    }
    return result;
}

// 研究者モード用のログ解析ツール
export default function HistoryLogApp({
    space, setShowHistory, select, children,
}) {
    const { user, items } = useAuthContext();
    const [firstable, setFirstable] = React.useState(true);
    const [history, setHistory] = React.useState([]);
    const [csvData, setCsvData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    let [updated, setUpdated] = React.useState(0);

    const sid = "_public";

    useEffect(() => {
        const unsubscribed = async () => {
            if (firstable) {
                setFirstable(false);
                setLoading(true);
                const data = await MonshinService.getHistoryLog(sid);
                setHistory(data);

                // csv
                const nothing = {};
                const csvData = [
                    [
                        "日時",
                        "モード",
                        "名前",
                        "主訴",
                        "年齢",
                        "性別",
                        "症状",
                        "疾患候補",
                        "鑑別診断結果",
                        "除外",
                        "ノート"],
                ];
                for (const v of data) {
                    const user = getUser(v);
                    if (!user) {
                        nothing[v.uid] = true;
                    }
                    if (user && user.mode === "test") {
                        continue;
                    }
                    const item = [
                        getTimestamp(v),
                        getUserMode(user, v),
                        getUserName(user, v),
                        getMain(v),
                        getAge(v),
                        getSex(v),
                        getPositive(v, true),
                        getResult(v, true),
                        getDResult(v, true),
                        getIResult(v, true),
                        v.note,
                    ];
                    csvData.push(item);
                }
                setCsvData(csvData);
                console.log(nothing);

                setUpdated(updated + 1);
                setLoading(false);
            }
        };
        unsubscribed();
    }, []);

    function label(s) {
        if (!s) {
            s = "";
        }
        if (s.length > 20) {
            s = s.substring(0, 20) + "...";
        }
        return s;
    }

    function getMain(v) {
        return v.positive[0];
    }

    function getAge(v) {
        let age = v.age;
        if (v.age >= 0) { age += "歳" } else { age = "年齢不明" }
        return age;
    }

    function getSex(v) {
        let sex = v.gender;
        return sex;
    }

    function getPositive(v, isCsv) {
        const results = [];
        const ignores = ["男性", "女性", "性別不明",
            "年齢不明", "乳児", "幼児", "小児", "若年", "中年", "高齢", "超高齢"];
        const onset = reverse(items.onset);
        const duration = reverse(items.duration);
        const time_course = reverse(items.time_course);
        const repeat = reverse(items.repeat);
        for (let item of v.positive) {
            if (item === getMain(v) || ignores.includes(item)) {
                continue;
            } else if (Object.keys(onset).includes(item)) {
                item = onset[item];
            } else if (Object.keys(duration).includes(item)) {
                item = duration[item];
            } else if (Object.keys(time_course).includes(item)) {
                item = time_course[item];
            } else if (Object.keys(items.repeat).includes(item)) {
                item = repeat[item];
            }
            results.push(item);
        }
        if (!isCsv) {
            return results.length > 0 ? results.map((w, i) => {
                return (<>{label(w)}<br /></>)
            }) : "-";
        } else {
            let s = "";
            if (results.length > 0) {
                for (const w of results) {
                    if (s.length > 0) {
                        s += ",";
                    }
                    s += label(w);
                }
            }
            return s;
        }
    }

    function getResult(v, isCsv) {
        if (!isCsv) {
            return v.result.length > 0 ? v.result.map((w, i) => {
                if (i >= 10) {
                    return null;
                }
                return (<>{label(w.disease)}<br /></>)
            }) : "-";
        } else {
            let s = "";
            if (v.result && v.result.length > 0) {
                for (const w of v.result) {
                    if (s.length > 0) {
                        s += ",";
                    }
                    s += label(w.disease);
                }
            }
            return s;
        }
    }

    function getDResult(v, isCsv) {
        if (!isCsv) {
            return v.ddxs && v.ddxs.length > 0 ? v.ddxs.map((w, i) => {
                if (i >= 10) {
                    return null;
                }
                return (<>{label(w)}<br /></>)
            }) : "-";
        } else {
            let s = "";
            if (v.ddxs && v.ddxs.length > 0) {
                for (const w of v.ddxs) {
                    if (s.length > 0) {
                        s += ",";
                    }
                    s += label(w);
                }
            }
            return s;
        }
    }

    function getIResult(v, isCsv) {
        if (!isCsv) {
            return v.exclusions && v.exclusions.length > 0 ? v.exclusions.map((w, i) => {
                if (i >= 10) {
                    return null;
                }
                return (<>{label(w)}<br /></>)
            }) : "-";
        } else {
            let s = "";
            if (v.exclusions && v.exclusions.length > 0) {
                for (const w of v.exclusions) {
                    if (s.length > 0) {
                        s += ",";
                    }
                    s += label(w);
                }
            }
            return s;
        }
    }

    function getTimestamp(v) {
        return TimeService.datetimeString(v.timestamp);
        // if (typeof(v.timestamp) === 'string') {
        //     return v.timestamp.substring(0, 10) + ' ' + v.timestamp.substring(11, 16);
        // } else {
        // }
    }

    function getUser(v) {
        const mapping = {
            // aimon
            "QEdi1tU6o8OSHMgcnCn0YpM9pe43": {
                mode: "test", // aimon
                name: "HAROLABO Hirano",
            },
            "fKH1piMkmXTrw5oYOoJ8tlPACtv2": {
                mode: "test", // aimon
                name: "HAROLABO Hirano",
            },
            "DJhq7TkTE2dosJVWwLUksWZNKg63": {
                mode: "test", // pockemon
                name: "HAROLABO Hirano",
            },
            "9oUX9D56etfdY7w6Sw7gvZtpLDj1": {
                mode: "test", // pockemon
                name: "HAROLABO Hirano",
            },
            "QY2XxD3MEbfpcoEuqi83M7V9xUT2": {
                mode: "aimon",
                name: "柳田育孝",
            },
            "ygmm5dRQYBV15FsmCSfSP18M4wd2": {
                mode: "pockemon",
                name: "柳田育孝",
                mail: "y.yanagita@gmail.com"
            },
            "dKg0Z4GAtjWTBSnKJNSGN3BksHK2": {
                mode: "aimon",
                name: "Kazutaka Noda",
            },
            "SQ8tv4EIsPbZNeFQNMR7ajRKzYy1": {
                mode: "pockemon",
                name: "Kazutaka Noda",
            },
            "Pzxky2ac50OSC06RVreq63AelCL2": {
                mode: "aimon",
                name: "横川大樹",
            },
            "LxRk7LhKACXHhIro4tT1KUmgv5q2": {
                mode: "pockemon",
                name: "横川大樹",
            },
            "DCzGeB9XbiY7HoC7c31jB7FVQRh2": {
                mode: "pockemon",
                name: "柴山栄太朗",
            },
            // 不明
            "9cMtdzqa2hhM6LsWtDhMAYTnGCX2": {
                mode: "pockemon",
                name: "o yo",
                mail: "traveljump2@gmail.com",
            },
            "KTvSzV6UlTg0nlewj5Yc6hlbLti1": {
                mode: "pockemon",
                mail: "yasuushiushi@gmail.com",
            },
            "noBPXmoA71UoTZ3AjiwVUCXASaO2": {
                mode: "pockemon",
                mail: "yuyuko.013@gmail.com",
            },
            "oNmxELXcz0dr0heVhj1TQSbkHPC3": {
                mode: "pockemon",
                mail: "igs.otake@gmail.com",
            },
        };
        if (Object.keys(mapping).includes(v.uid)) {
            return mapping[v.uid];
        } else {
            return null;
        }
    }

    function getUserMode(u, v) {
        const modes = {
            "aimon": "研究者",
            "pockemon": "利用者",
            "test": "テスト"
        };
        if (u) {
            return modes[u.mode];
        } else {
            return "利用者";
        }
    }

    function getUserName(u, v) {
        if (u) {
            return u.name ? u.name : u.mail;
        } else {
            return v.uid;
        }
    }

    function selectResult(v) {
        if (select) {
            const id = v.id;
            const main = v.main;
            const age = v.age;
            const sex = v.sex;

            const results = [];
            const ignores = ["男性", "女性", "性別不明",
                "年齢不明", "乳児", "幼児", "小児", "若年", "中年", "高齢", "超高齢"];
            const onset = reverse(items.onset);
            const duration = reverse(items.duration);
            const time_course = reverse(items.time_course);
            const repeat = reverse(items.repeat);
            for (let item of v.positive) {
                if (item === main || ignores.includes(item)) {
                    continue;
                } else if (Object.keys(onset).includes(item)) {
                    item = onset[item];
                } else if (Object.keys(duration).includes(item)) {
                    item = duration[item];
                } else if (Object.keys(time_course).includes(item)) {
                    item = time_course[item];
                } else if (Object.keys(items.repeat).includes(item)) {
                    item = repeat[item];
                }
                results.push(item);
            }

            const case1 = new CaseService({
                main: v.main,
                age: v.age,
                sex: v.gender,
                symptoms: results,
            }, {
                ddxs: v.ddxs,
                exclusions: v.exclusions,
            });
            select(case1);
            setShowHistory(false);
        }
    }

    return (
        <Box>
            <Appbar
                space={space}
                setShowSetting={setShowHistory}
                select={select}
                csvData={csvData}
                loading={loading}
            />
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                w={'calc(100vw)'}
                h={"calc(100vh - 48px)"}
                overflowX="auto"
                overflowY="auto"
            >
                {/* {loading ? <Loading /> : null} */}
                <TableContainer overflowX="unset" overflowY="unset">
                    <Table variant='simple' size='md'>
                        <Thead position="sticky" top={0} zIndex="docked" bg="white">
                            <Tr>
                                <Th>日時</Th>
                                <Th>モード</Th>
                                <Th>名前</Th>
                                <Th>主訴</Th>
                                <Th>年齢</Th>
                                <Th>性別</Th>
                                <Th>症状</Th>
                                <Th>疾患候補</Th>
                                <Th>鑑別診断結果</Th>
                                <Th>除外</Th>
                                <Th>ノート</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {history.map((v, i) => {
                                const user = getUser(v);
                                if (user && user.mode === "test") {
                                    return null;
                                }
                                return (<Tr
                                    onClick={() => {
                                        selectResult(v);
                                    }}
                                >
                                    <Td>{getTimestamp(v)}</Td>
                                    <Td>{getUserMode(user, v)}</Td>
                                    <Td>{getUserName(user, v)}</Td>
                                    <Td>{getMain(v)}</Td>
                                    <Td>{getAge(v)}</Td>
                                    <Td>{getSex(v)}</Td>
                                    <Td>{getPositive(v)}</Td>
                                    <Td>{getResult(v)}</Td>
                                    <Td>{getDResult(v)}</Td>
                                    <Td>{getIResult(v)}</Td>
                                    <Td>{v.note}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* <MySheet
                current={"a"}
                columns={columns}
                data={data}
                onUpdate={(note, columns, data) => {
                    // for (const row of data) {
                    //     if (row.name === 'サイドバーの背景URL') {
                    //         const value = row.value;
                    //         setSidebarUrl(value);
                    //         console.log(value);
                    //     }
                    // }
                }}
            /> */}
            </Box>
        </Box>
    );
}

// アプリバー
const Appbar = ({ space, select, setShowSetting, csvData, loading, ...rest }) => {
    const { user, license } = useAuthContext();
    const navigate = useNavigate();

    const name = TimeService.formatDateTime();

    return (
        <>
            <Flex
                ml={{ base: 0, md: 0 }}
                px={{ base: 4, md: 4 }}
                height="12"
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                {...rest}>
                {<CloseButton size='sm'
                    onClick={() => { setShowSetting(false); }}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />}
                <Box p="2">
                    {"検索ログ" + (select ? "（クリックすると選択した条件で検索します）" : "")}
                </Box>
                <div style={{ flexGrow: 1 }} />
                {!loading ? <CSVLink className="btn btn-primary" data={csvData} filename={name + ".csv"}>CSV Download</CSVLink> : null}
            </Flex>
        </>
    );
};
