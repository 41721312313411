
import {
    useColorModeValue,
} from '@chakra-ui/react';

class Colors {
    white = useColorModeValue('white', 'gray.900');
    whitesmoke = useColorModeValue('whitesmoke', 'gray.900');
    gray = useColorModeValue('gray.200', 'gray.700');
    gray700 = useColorModeValue('gray.700', 'whiteAlpha.900');
    active = useColorModeValue('teal.300', 'teal.700');
    hover = useColorModeValue('teal.100', 'teal.900');
}

export default Colors;