
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    IconButton,
    Alert,
    AlertIcon,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import ContractService from '../../../Services/ContractService';
import uuid from 'react-uuid';
import Loading from '../Components/Loading';
import Colors from '../../../Constants/Colors';
import Waiting from '../Components/Waiting';
import TimeService from '../../../Services/TimeService';

// ワークスペースの設定を行うアプリ
export default function TenantManager({
    space, children,
}) {
    const colors = new Colors();
    const gray700 = colors.gray700;
    const white = colors.white;
    const { user, state } = useAuthContext();
    const toast = useToast();

    let [loading, setLoading] = React.useState(false);
    let [apiId, setApiId] = React.useState(null);
    let [key1, setKey1] = React.useState("");
    let [tenants, setTenants] = React.useState([]);

    const sid = user.uid;
    const su = user.email === "yosuke.hirano@harolabo.com";

    useEffect(() => {
        const func = async () => {
            setLoading(true);
            // 最新のプラン情報を得る
            const result = await ContractService.getContract(sid);
            if (result) {
                const apiid = user.uid;
                const apikey = result.secrets[0];

                // 最新のプラン情報を得る
                const tenants = await ContractService.getTenantsApi(apiid, apikey);
                if (tenants) {
                    setTenants(tenants);
                }

                setApiId(apiid);
                setKey1(apikey);
            }
            setLoading(false);
        };
        func();
    }, []);

    function noApi() {
        return (<>
            <Alert status='info'>
                <AlertIcon />
                テナントを利用するには、API契約が必要です
            </Alert>
        </>);
    }

    return (
        <Box
            color={gray700}
            bg={white}
            w={'calc(100vw - 200px)'}
            h={"calc(100vh - 48px)"}
            overflowY="auto"
            align="center"
            p={8}
        >
            <Box
                w={"full"}
                maxW={"600px"}
            >
                <VStack spacing={5}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        テナントの一覧
                    </Heading>

                    {loading ? <Waiting />
                        : !apiId ? noApi() : <>
                            {/* <Button onClick={async () => {
                                const id = uuid().replace(/-/g, '');
                                const name = "";
                                await ContractService.createTenantApi(apiId, key1, id, name);
                                const tenants = await ContractService.getTenantsApi(apiId, key1);
                                if (tenants) {
                                    setTenants(tenants);
                                }
                                setApiId(apiId);
                            }}>テナントを新規作成する</Button> */}
                            {renderTenants()}
                        </>}
                </VStack>
            </Box>
        </Box>
    );

    function renderTenants() {
        return (<>
            <TableContainer overflowX="unset" overflowY="unset">
                <Table variant='simple' size='md'>
                    <Thead position="sticky" top={0} zIndex="docked" bg="white">
                        <Tr>
                            <Th>テナント名</Th>
                            <Th>テナントID</Th>
                            <Th>作成日</Th>
                            <Th>状態</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.keys(tenants).map((v, i) => {
                            const t = tenants[v];
                            // console.log(t, t.created, TimeService.datetimeString(t.created));
                            return (
                                <Tr>
                                    <Td>{t.name}</Td>
                                    <Td>{t.id}</Td>
                                    <Td>{TimeService.datetimeString(t.created)}</Td>
                                    <Td>{t.status}</Td>
                                    {/* <Td><Button onClick={()=>{
                                        
                                    }}>編集</Button></Td>
                                    <Td><Button onClick={()=>{

                                    }}>無効化</Button></Td>
                                    <Td><Button onClick={()=>{

                                    }}>削除</Button></Td> */}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </>);
    }
}