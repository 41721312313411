
import React from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import ResizeTextarea from "react-textarea-autosize";
import { Text, useColorModeValue } from "@chakra-ui/react";

export default function InputArea(props) {
    return (<ReactTextareaAutocomplete
        disabled={props.loading}
        value={props.value}
        maxLength={1000}
        onChange={!props.loading ? props.onChange : null}
        onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }}
        onBlur={() => {
            if (props.onBlur) {
                props.onBlur();
            }
        }}
        onKeyDown={(e) => {
            if (props.onKeyDown) {
                props.onKeyDown(e);
            }
        }}
        textAreaComponent={ResizeTextarea}
        placeholder={props.placeholder}
        style={{
            overflow: 'hidden',
            padding: "12px",
            border: "1px solid lightgray",
            borderRadius: "8px",
            resize: "none",
            fontSize: "0.8em",
            marginBottom: '-7px', // 仕組み上、下に7pxできる
        }}
        loadingComponent={() => null}
        minChar={0}
        trigger={{
        }}
        onPaste={(e) => {
            if (!e.clipboardData
                || !e.clipboardData.types
                || (e.clipboardData.types.length != 1)
                || (e.clipboardData.types[0] != "Files")) {
                // 普通のテキストはpreventしない。preventするとundoがきかなくなる。
                // var textFeildValue = translatePastedText(e.clipboardData.getData("text"));
                // insertText(e, textFeildValue);
            } else {
                e.preventDefault();
            }
            // console.log(e);
        }}
    />);
}