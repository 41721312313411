
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';

// ワークスペースの設定を行うアプリ
export default function SystemManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(true);
    let [logs, setLogs] = React.useState({});
    const { user, setUser } = useAuthContext();
    const toast = useToast();

    // プラン変更確認ダイアログ
    let [isChangeMemberRoleModal, setChangeMemberRoleModal] = React.useState(null);

    const api_url = config.api;
    const success_url = config.host + "/home"

    useEffect(() => {
        const func = async () => {
            // 最新のプラン情報を得る
            const sid = "_public";
            const days = {};
            for (const month of ["04", "05", "06", "07", "08"]) {
                const result = await LogService.getLogs(sid, "2024" + month);
                console.log(month, result);
                if (result) {
                    // 日ごとの集計
                    for (const dkey of Object.keys(result)) {
                        // 認証数(/meの実行回数)
                        let diseases = 0;
                        let monshins = 0;
                        // ユーザーごとの集計
                        const users = {};
                        const ws = result[dkey];
                        for (const wkey of Object.keys(ws)) {
                            const us = ws[wkey];
                            for (const ukey of Object.keys(us)) {
                                const st = us[ukey];
                                // console.log(dkey, wkey, ukey, st);
                                if (!users[ukey]) {
                                    users[ukey] = {
                                        disease: 0,
                                        monshin: 0,
                                    };
                                }
                                if (st.disease) {
                                    users[ukey].disease += st.disease;
                                    diseases += st.disease;
                                }
                                if (st.monshin) {
                                    users[ukey].monshin += st.monshin;
                                    monshins += st.monshin;
                                }
                            }
                        }

                        // 日ごとの集計
                        const key = month + "/" + dkey
                        days[key] = {
                            // アクティブユーザー数
                            num_users: Object.keys(users).length,
                            // アクティブワークスペース数
                            num_wss: Object.keys(ws).length,
                            // 問診回数
                            num_monshins: monshins,
                            // 推論実行回数
                            num_diseases: diseases,
                            users: users,
                        };
                    }
                }
                console.log(days);
                setLogs(days);
            }
            setLoading(false);
        };
        func();
    }, []);

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
        h={"calc(100vh - 48px)"}
        overflowX="hidden"
        overflowY="auto"
    >{loading ? <>
        loading...
    </>
        : <>
            <Box justifyContent="center" w={'full'}>
                <Box textAlign="center" py={10} px={6}>
                    <Heading as="h2" size="lg" mt={6} mb={2}>
                        ログ
                    </Heading>
                </Box>

                <TableContainer py={10}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>日付</Th>
                                <Th>アクティブユーザー数</Th>
                                <Th>アクティブWS数</Th>
                                <Th>問診回数</Th>
                                <Th>推論実行回数</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.keys(logs).map((d, i) => {
                                const v = logs[d];
                                return (
                                    <Tr>
                                        <Td>{d}</Td>
                                        <Td>{v.num_users}</Td>
                                        <Td>{v.num_wss}</Td>
                                        <Td>{v.num_monshins}</Td>
                                        <Td>{v.num_diseases}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
        }
    </Box>);
}
