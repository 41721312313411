const config = {
    app: "monshin",
    appName: 'ポケット問診',
    dev: false,
    apipath: "/pockemon"
};

config.sys = "aimon";
config.api = "https://api.pocketmonshin.com";
config.host = "https://" + window.location.hostname;

if (window.location.hostname === 'localhost') {
    config.api = "http://127.0.0.1:8001"; // local環境
    // config.api = "https://aimon-api-dev-sw32krii4q-an.a.run.app"; // dev環境
    config.host = "http://localhost:3001";
    config.dev = true;
    config.apipath = "/pockedev";
}

if (window.location.hostname === 'aimon-7dc94.web.app') {
    config.dev = true;
    config.apipath = "/pockedev";
}


// console.log(config.sys, config.app, config.api);

export default config