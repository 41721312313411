import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Icon,
    useColorModeValue,
    createIcon,
    chakra,
    Link,
    VisuallyHidden,
    SimpleGrid,
    HStack,
    VStack,
    useBreakpointValue,
    Flex,
    Image,
    Avatar,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaTwitter, FaYoutube, FaPlay } from 'react-icons/fa';
import {
    FcDocument,
    FcCalculator, FcSportsMode, FcDataSheet,
    FcAssistant, FcDonate, FcInTransit
} from 'react-icons/fc';
import React, { useEffect } from 'react';

import { version } from '../../version';
import Footer from '../footer';

import ReactMarkdown from 'react-markdown'

import config from '../../config';

export default function TermsPage(props) {
    const [title, setTitle] = React.useState("");
    const [post, setPost] = React.useState("");

    useEffect(() => {
        let title = null;
        let data = null;

        switch (props.page) {
            case 'termsofuse':
                title = "利用規約";
                data = '/terms/termsofuse.md';
                break;
            case 'commerce':
                title = "特定商取引法に基づく表示";
                data = '/terms/commerce.md';
                break;
        }
    
        setTitle(title);
        fetch(data)
            .then((r) => r.text())
            .then(text => {
                // 改行を有効にする
                text = text.replace(/\r\n/g, '\n');
                text = text ? text.replace(/\n/g, '  \n') : "";

                setPost(text);
            });
    }, []);

    return (
        <>
            <Stack direction={{ base: 'column', md: 'row' }}>
                <Flex p={4} flex={1} align={'top'} justify={'top'} position="absolute">
                    <Heading
                        fontSize={"xl"}
                        fontWeight={600}
                        // fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        <Text as={'span'} color={'blue.400'}>
                            <Link href="/">{config.appName}</Link>
                        </Text>
                    </Heading>
                </Flex>
            </Stack>

            <Stack
                bg={useColorModeValue('gray.50', 'gray.800')}
                py={16}
                px={8}
                spacing={{ base: 8, md: 10 }}
                align={'center'}
                direction={'column'}>
                <Stack marginTop={0} spacing={4} as={Container} maxW={'4xl'} textAlign={'center'}>
                    <Heading fontSize={'3xl'}>{title}</Heading>
                </Stack>
            </Stack>

            <Box p={4} py={{ base: 8, md: 10 }}>
                <Container maxW={'4xl'} mt={10} mb={10}>
                    <div className="markdown">
                        <ReactMarkdown
                            // className={style.reactMarkDown}
                            // remarkPlugins={[gfm]}
                            children={post}
                            linkTarget={"_blank"}
                        // components={{
                        //     img: BlogImage
                        // }}
                        />
                    </div>
                </Container>
            </Box>

            {/* フッター */}
            <Footer />
        </>
    );
}
