import React from 'react';
import AimonApp from './App/AimonApp';
import ResultApp from './App/AimonApp/result';
import LoginApp from './App/LoginApp';
import { AuthProvider } from './Contexts/AuthContext';

export default function App(props) {
    return (
        <AuthProvider>
            {props.path === "signin" ? <LoginApp /> :
                props.path === "result" ? <ResultApp /> :
                    <AimonApp />
            }
        </AuthProvider>
    );
}