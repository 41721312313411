
import React, { useEffect } from 'react';
import {
    Divider,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
} from '@chakra-ui/react';
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { MySheet } from '../Components/mysheet';
// ワークスペースの設定を行うアプリ
export default function KanaManager({
    space, children,
}) {
    const columns = [
        { data: 'category', title: '分類', type: 'text', width: 100 },
        { data: 'name', title: '単語', type: 'text', width: 100 },
        { data: 'kana ', title: 'カタカナ', type: 'text', width: 100 },
        { data: 'hiragana', title: 'ひらがな', type: 'text', width: 100 },
        { data: 'romaji', title: 'ローマ字', type: 'text', width: 100 },
    ];
    const data = [
        { name: '' }
    ]

    return (
        <Flex
            bg={useColorModeValue('white', 'gray.900')}
            w={'calc(100vw - 250px)'}
            h={"calc(100vh - 48px)"}
            overflowY="auto"
        >
            <MySheet
                current={"a"}
                columns={columns}
                data={data}
                onUpdate={(note, columns, data) => {

                }}
            />
        </Flex>
    );
}