import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Center,
    Image,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

import { useAuthContext } from '../../Contexts/AuthContext';
import SignService from '../../Services/SignService';
import Copyright from './copyright';
import logo from './logo.png';

export default function LoginApp() {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [signing, setSigning] = useState(false);
    const gray50 = useColorModeValue('gray.50', 'gray.800');
    const white = useColorModeValue('white', 'gray.700');

    if (signing && user) {
        setSigning(false);
    }

    if (signing) {
        return (<>ログイン中</>);
    }

    return ((user) ?
        <Navigate to="/search" /> :
        <>
            <Flex
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                bg={gray50}>
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Image src={logo} />
                        {/* <Heading fontSize={'4xl'}>ポケット問診</Heading> */}
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={white}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            <Stack align={'center'}>
                                <Text fontSize={'lg'} color={'gray.600'}>
                                    Googleアカウントでログインできます。
                                </Text>
                            </Stack>
                            {/* <FormControl id="email">
                                    <FormLabel>Email address</FormLabel>
                                    <Input type="email" />
                                </FormControl>
                                <FormControl id="password">
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" />
                                </FormControl> */}
                            <Stack spacing={10}>
                                <Center>
                                    <Alert status='info'  variant='solid' backgroundColor="gray">
                                        <AlertIcon />
                                        <div>
                                        <a target="_blank" rel="noopener noreferrer" href="/termsofuse">利用規約</a>
                                        に同意したうえで<br />ログインしてください
                                        </div>
                                    </Alert>
                                </Center>
                                <Button
                                    onClick={() => {
                                        // setSigning(true);
                                        SignService.signInWithGoogle();
                                    }}
                                    w={'full'} variant={'outline'} leftIcon={<FcGoogle />}>
                                    <Center>
                                        <Text>Sign in with Google</Text>
                                    </Center>
                                </Button>
                                {/* <Center>
                                    <Link color={'blue.400'}
                                        onClick={() => navigate("/signup")}
                                    >
                                        ユーザー登録がお済みでない方はこちら
                                    </Link>
                                </Center> */}
                                <Stack pt={6}>
                                    <Copyright />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
        </>
    );
}