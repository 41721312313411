
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    IconButton,
    Avatar,
    Box,
    Container,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Input,
    FormControl,
    FormLabel,
    Alert,
    AlertIcon,
    RadioGroup,
    Radio,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Textarea,
    Tooltip,
    Tabs,
    TabList,
    Tab,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useToast,
    ModalBody,
    Spinner,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiChevronLeft,
    FiFlag,
    FiX,
    FiExternalLink,
} from 'react-icons/fi';

import {
    GrFlagFill,
} from "react-icons/gr";

import { useNavigate, Navigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { useDropzone } from 'react-dropzone';

import { useAuthContext } from '../../Contexts/AuthContext';
import SignService from '../../Services/SignService';
import MonshinService from '../../Services/MonshinService';
import SettingsApp from './Settings';
import Loading from './Components/Loading';
import WordService from '../../Services/WordService';
import { Show, Hide } from '@chakra-ui/react'
import HistoryApp from './History';
import CaseService from '../../Repositories/CaseService';
import config from '../../config.js';

const FormItem = ({ label, children, ...rest }) => {
    return (
        <FormControl p="2">
            <FormLabel>{label}</FormLabel>
            {children}
        </FormControl>
    )
};

// アプリバー
export const MobileNav = ({ selectedState, setSelectedState, selected, setSelected, isShowSidebar, setShowSetting, setShowHistory, ...rest }) => {
    const { user, items, license } = useAuthContext();
    const navigate = useNavigate();
    const toast = useToast();
    const sid = user.uid;
    const gray200 = useColorModeValue('gray.200', 'gray.700');

    const [subModal, setSubModal] = React.useState(false);
    const [isdbModal, setIsdbModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let [age, setAge] = React.useState(setSelectedState.age);
    let [sex, setSexe] = React.useState(selected['sex']);

    const handleLogout = () => {
        SignService.signOut();
        navigate('/signin');
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        onDrop: acceptedFiles => {
            for (const file of acceptedFiles) {
                upload(file);
            }
        }
    });

    async function upload(file) {
        setLoading(true);
        const res = await MonshinService.postFileAsync(sid, file);
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
            setIsdbModal(false);
            toast({
                title: 'ISDBを更新しました',
                //   description: "We've created your account for you.",
                status: 'success',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'ISDBをアップロードできませんでした',
                //   description: "We've created your account for you.",
                status: 'error',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    async function publish() {
        setLoading(true);
        const res = await MonshinService.publishISDB(sid);
        setLoading(false);
        if (res.status === 200) {
            setIsdbModal(false);
            toast({
                title: 'ISDBをリリースしました',
                //   description: "We've created your account for you.",
                status: 'success',
                position: 'bottom-right',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const dev = (config.dev && license && license.role === "dev");

    return (
        <>
            <Flex
                // ml={{ base: 0, md: isShowSidebar ? 600 : 0 }}
                px={{ base: 4, md: 4 }}
                height="12"
                justifyContent={'space-between'}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                {...rest}>
                <Button
                    display={{ base: 'none', md: 'flex' }}
                    onClick={() => {
                        navigate('/search');
                    }}
                    leftIcon={<Icon as={FiChevronLeft} />} colorScheme='gray' variant='link'>
                    主訴を選ぶ
                </Button>
                <Button
                    display={{ base: 'flex', md: 'none' }}
                    onClick={() => {
                        navigate('/search');
                    }}
                    leftIcon={<Icon as={FiChevronLeft} />} colorScheme='gray' variant='link'>
                </Button>
                <Flex
                    justifyContent={'space-between'}
                    alignItems="center">
                    <Text p="2"
                        fontSize={{ base: '0.9em', md: '1.2em' }}>
                        {selected['main']}
                    </Text>
                    <Tooltip hasArrow
                        label='クリックすると年齢・性別を変更できます'
                        bg='gray.300' color='black'>
                        <Link
                            onClick={() => {
                                setSubModal(true);
                            }}>
                            <Text p="2"
                                fontSize={{ base: '0.9em', md: '1.2em' }}>
                                （{selected["ageLabel"]}・{selected["sex"]}）
                            </Text>
                        </Link>
                    </Tooltip>
                    {/* {selectedState['id']} */}
                </Flex>

                <HStack spacing={{ base: '0', md: '6' }}>
                    <Flex alignItems={'center'}>
                        <Button m={4} size={'sm'} variant='outline'
                            onClick={() => {
                                setShowHistory(true);
                            }}>検索履歴</Button>
                        {dev ? <Button mr={4} size={'sm'} variant='outline'
                            onClick={() => {
                                setIsdbModal(true);
                            }}>ISDB Upload</Button> : null}
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <Avatar
                                        size={'sm'}
                                        src={user.photoURL}
                                    />
                                    {/* <VStack
              display={{ base: 'none', md: 'flex' }}
              alignItems="flex-start"
              spacing="1px"
              ml="2">
              <Text fontSize="sm">Justina Clark</Text>
              <Text fontSize="xs" color="gray.600">
                Admin
              </Text>
            </VStack> */}
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue('white', 'gray.900')}
                                borderColor={useColorModeValue('gray.200', 'gray.700')}>
                                {/* <MenuItem onClick={() => {
                                    setShowHistory(true);
                                }}>検索履歴</MenuItem> */}
                                <MenuItem onClick={() => {
                                    setShowSetting(true);
                                }}>設定</MenuItem>
                                <MenuDivider />
                                <Link isExternal href="https://docs.google.com/forms/d/e/1FAIpQLSderfg0jeK2dLmNW2Z1q_sRve8OhCSD02wGZcp4rHwY9szIjQ/viewform">
                                    <MenuItem>{"フィードバックを送る"}</MenuItem>
                                </Link>
                                <MenuDivider />
                                <MenuItem onClick={() => {
                                    handleLogout();
                                }}>ログアウト</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>

            <Modal isOpen={subModal} onClose={() => {
                selectedState.update({
                    age: age,
                    sex: sex
                });
                // console.log(selectedState);
                setSelectedState(selectedState);
                setSelected(selectedState.state);
                setSubModal(false);
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>年齢・性別を変更します</ModalHeader>
                    <ModalBody>
                        <Box p={4}>
                            <FormItem label="年齢">
                                {renderNumber(items.age, "", "数字を入力", age,
                                    (v) => {
                                        setAge(v.target.value);
                                    }, (v) => {
                                    })}
                            </FormItem>
                            <FormItem label="性別">
                                {renderRadioSelect(items.sex, "", sex, (v) => {
                                    setSexe(items.sex[v]);
                                })}
                            </FormItem>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={isdbModal} onClose={() => {
                setIsdbModal(false);
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ISDBをアップロードします</ModalHeader>
                    <ModalBody>
                        <Box p={4}>
                            {!loading ? <><div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Box
                                    borderWidth={1}
                                    borderColor={gray200}
                                    p={8}
                                >
                                    {"ISDBファイルをD&D / クリックしてISDBファイルを選択"}
                                </Box>
                            </div>
                                <Button m={4} size={'sm'} variant='outline'
                                    onClick={() => {
                                        publish();
                                    }}>{"リリース"}</Button>
                            </> : null}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );

    function renderRadioSelect(list, placeholder, defValue, handleChange) {
        if (!list) {
            return <div />;
        }
        let index = 0;
        for (const key of list) {
            if (key === defValue) {
                break;
            }
            index++;
        }
        return (
            <Tabs variant='soft-rounded' colorScheme='green'
                index={index}
                onChange={(e) => { handleChange(e) }}>
                <TabList>
                    {list.map((data, index) => {
                        return <Tab key={index} value={data}>{data}</Tab>;
                    })}
                    <Tab key={"不明"} value={"不明"}>{"不明"}</Tab>
                </TabList>
            </Tabs>
        )
    }

    function renderNumber(list, labbel, placeholder, defValue, handleChange, handleEnter) {
        return (
            <Input
                placeholder={placeholder}
                value={defValue}
                onChange={handleChange}
                onEnter={handleEnter}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleEnter(e.target.value);
                    }
                }}
                allowClear />
        );
    }
};
