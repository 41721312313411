import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth, firebaseDatabase } from '../firebase.js'
import { ref, set, get, child } from "firebase/database";
import uuid from 'react-uuid';

import config from '../config';

class UserDataService {

    // ユーザーデータを作成
    async createUserData(uid, user, name) {
        const userdata = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            spaces: {},
        };
        userdata.spaces[user.uid] = name;
        return await this.setUserData(uid, userdata);
    }

    // ユーザーデータを上書きする
    // ユーザーデータは本人のみ変更できるルールセット
    async setUserData(uid, userdata) {
        const db = firebaseDatabase;
        try {
            set(ref(db, `users/${uid}`), userdata);
            return true;
        } catch (e) {
            console.error(e, userdata);
            return false;
        }
    }

    // ユーザーデータを取得する
    // ユーザーデータは本人のみ参照できるルールセット
    async getUserData(uid) {
        const dbRef = ref(firebaseDatabase);
        const snapshot = await get(child(dbRef, `users/${uid}`));
        if (snapshot.exists) {
            return snapshot.val();
        } else {
            return null;
        }
    }

    async getLicenseData(uid) {
        const dbRef = ref(firebaseDatabase);
        const snapshot = await get(child(dbRef, `lics/${uid}`));
        if (snapshot.exists) {
            return snapshot.val();
        } else {
            return null;
        }
    }
};

export default new UserDataService();
