import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth } from '../firebase.js'

import config from '../config';

import { Stripe, loadStripe } from '@stripe/stripe-js/pure'

class StripeService {

    api = config.api;

    async getApiAuthorizationAsync(apiid) {
        // トークンの認証切れに対応
        let token = null;
        const currentUser = firebaseAuth.currentUser;
        if (currentUser) {
            token = await currentUser.getIdToken();
        } else {
            console.log("ERROR");
        }

        const apikey = '';
        return {
            "Api-Id": apiid,
            "Api-Key": apikey,
            Authorization: "Bearer " + token
        };
    }

    async postAsync(apiid, command, data) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.post(url, data, { headers: await this.getApiAuthorizationAsync(apiid) });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }
    
    async getstripe(sid, lid) {
        const plan = {
            // ライセンスID(ユーザーIDと共通)
            lid: lid,
            // ライセンスタイプ
            type: "user",
            // プラン
            plan: 0,
        };
        const response = await this.postAsync(sid, "/plan/v1/get-plan", plan);
        if (response && response.status === 200) {
            return response.data;
        } else {
            console.log(response);
        }
        return null;
    }

    // サブスクリプション変更
    async stripe(sid, plan) {
        const response = await this.postAsync(sid, "/plan/v1/ready-plan", plan);
        if (response && response.status === 200) {
            const session = response.data;
            if (session.sessionId === "not_changed") {
                return session.sessionId;
            }
            if (session.sessionId === "change_at_next_month") {
                return session.sessionId;
            }

            const stripe = await loadStripe("pk_test_51Hwmp7Kpo4Tbto4NWbdyOhCyCJFAJgQspBk87bSY0BFwSkRkTt1Gh4HIG1C5blIc1mnbfxNh4NYJe2zqCUGQuICs00II6bXiQf")
            console.log(stripe)
            const { error } = await stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: session.sessionId,
            })
        } else {
            console.log(response);
        }
        return null;
    }
}

export default new StripeService();
