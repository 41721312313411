
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';
import MonshinService from '../../../Services/MonshinService';

// ワークスペースの設定を行うアプリ
export default function DiseaseManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(false);
    let { db, setDb } = useAuthContext();
    const toast = useToast();

    let [status, setStatus] = React.useState("noload");
    let [diseases, setDeseases] = React.useState([]);
    let [searchText, setSearchText] = React.useState("");
    let [disease, setDesease] = React.useState(null);
    let [isCase, setIsCase] = React.useState(false);
    let [accs, setAccs] = React.useState([]);

    useEffect(() => {
        if (db) {
            const diseases = MonshinService.buildIsdb(db);
            setDb(db);
            setDeseases(diseases);
            calc(db, diseases);
            setStatus("load");
        }
    }, []);

    // // サーバーからISDBをロードする
    // async function load() {
    //     setLoading(true);
    //     db = await MonshinService.getISDB();
    //     const diseases = MonshinService.buildIsdb(db);
    //     setDb(db);
    //     setDeseases(diseases);
    //     setLoading(false);
    // }

    let n = 0;
    let h1 = "calc(70vh - 48px)";

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
    >
        <Flex
            h={h1}
        >
            <Box
                w={"400px"}
                h={h1}
                overflowX="auto"
                overflowY="auto"
            >
                <Box p={4}>
                    <Input
                        variant='outline'
                        placeholder='検索...'
                        onChange={(event) => {
                            setSearchText(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                    />
                </Box>
                <Box px={4}>
                    <Button
                        size={"sm"}
                        colorScheme={isCase ? "teal" : "gray"}
                        onClick={() => {
                            setIsCase(!isCase);
                        }}>{"症例あり"}</Button>
                </Box>
                {loading ? <>
                    loading...
                </>
                    : <TableContainer>
                        <Table size={"sm"}>
                            <Thead>
                                <Tr>
                                    <Th>有病率</Th>
                                    <Th>疾患</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {diseases.map((d, i) => {
                                    if (n > 40) {
                                        return null;
                                    }
                                    const name = d.name;
                                    if (searchText.length > 0) {
                                        if (name.indexOf(searchText) === -1) {
                                            return null;
                                        }
                                    }
                                    if (isCase) {
                                        let match = false;
                                        for (const item of db.cases) {
                                            if (item.name === d.name) {
                                                match = true;
                                            }
                                        }
                                        if (!match) {
                                            return null;
                                        }
                                    }

                                    n++;
                                    return (
                                        <Tr onClick={() => { setDesease(name); }}
                                            bgColor={disease === name ? "#C4F1F9" : "initial"}
                                        >
                                            <Td>{d.rate}</Td>
                                            <Td>{name}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
            </Box>
            <Box
                w={"full"}
                h={h1}
                overflowX="auto"
                overflowY="auto"
            >
                <Box p={4}>
                    {disease}
                </Box>

                <Tabs variant='unstyled'
                    onChange={(i) => {
                    }}>
                    <TabList>
                        <Tab>{"症状"}</Tab>
                        <Tab>{"症例"}</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg={"teal"}
                        borderRadius="1px"
                    />
                    <TabPanels pt={2}>
                        <TabPanel>
                            {renderWeight()}
                        </TabPanel>
                        <TabPanel>
                            {renderCase()}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>
        <Box
            h={"calc(30vh)"}
            overflowY="auto"
            bgColor={"whitesmoke"}>
            {renderAccuracy()}
        </Box>
    </Box>);

    function renderWeight() {
        const symptoms = {};
        let symptomlist = [];
        if (disease) {
            for (const item of db.isdb) {
                if (item.name === disease) {
                    symptoms[item.type] = item.symptoms;
                }
            }
            for (const item of diseases) {
                if (item.name === disease) {
                    symptomlist = item.symptoms;
                }
            }
            symptomlist.sort(function (a, b) {
                const as = getCategoryNo(a.category);
                const bs = getCategoryNo(b.category);
                if (as > bs) return -1;
                if (as < bs) return 1;
                return 0;
            });
        }

        return (disease ?
            <>
                <TableContainer>
                    <Table size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th>選択肢</Th>
                                <Th>症状</Th>
                                <Th>重み</Th>
                                <Th>A</Th>
                                <Th>B</Th>
                                <Th>C</Th>
                                <Th>D</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {symptomlist.map((d, j) => {
                                return (
                                    <Tr>
                                        <Td>{d.category}</Td>
                                        <Td>{d.name}</Td>
                                        <Td>{d.lsp.toFixed(2)}</Td>
                                        <Td>
                                            <Input size={"sm"}
                                                type="number"
                                                value={getWeight(symptoms, "A", d.name)}
                                                onChange={(v) => {
                                                    updateWeight(disease, "A", d, v.target.value);
                                                }}
                                                allowClear />
                                        </Td>
                                        <Td>
                                            <Input size={"sm"}
                                                type={"number"}
                                                value={getWeight(symptoms, "B", d.name)}
                                                onChange={(v) => {
                                                    updateWeight(disease, "B", d, v.target.value);
                                                }}
                                                allowClear />
                                        </Td>
                                        <Td>
                                            <Input size={"sm"}
                                                type={"number"}
                                                value={getWeight(symptoms, "C", d.name)}
                                                onChange={(v) => {
                                                    updateWeight(disease, "C", d, v.target.value);
                                                }}
                                                allowClear />
                                        </Td>
                                        <Td>
                                            <Input size={"sm"}
                                                type={"number"}
                                                value={getWeight(symptoms, "D", d.name)}
                                                onChange={(v) => {
                                                    updateWeight(disease, "D", d, v.target.value);
                                                }}
                                                allowClear />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </> : null);
    }

    function renderCase() {
        let caselist = [];
        if (disease) {
            for (const item of db.cases) {
                if (item.name === disease) {
                    caselist.push(item);
                }
            }
        }

        return (disease ?
            <>
                <TableContainer>
                    <Table size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th>ビネット</Th>
                                <Th>スコア</Th>
                                <Th>年齢</Th>
                                <Th>性別</Th>
                                <Th>発症様式</Th>
                                <Th>発症期間</Th>
                                <Th>症状推移</Th>
                                <Th>間欠期</Th>
                                <Th>主訴</Th>
                                <Th>他の症状</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {caselist.map((d, j) => {
                                const result = diagItem(db, diseases, d);
                                let w = null;
                                for (const item of result) {
                                    if (item.name === d.name) {
                                        w = item;
                                    }
                                }
                                console.log(w)

                                return (
                                    <Tr>
                                        <Td
                                            minW={"300px"}
                                            whiteSpace={"pre-wrap"}
                                        ><Text>{d.vignette}</Text></Td>
                                        <Td>{w ? w.w.toFixed(2) : "不明"}</Td>
                                        <Td>{d.age}</Td>
                                        <Td>{d.sex}</Td>
                                        <Td>{d.onset}</Td>
                                        <Td>{d.duration}</Td>
                                        <Td>{d.time_course}</Td>
                                        <Td>{d.repeat}</Td>
                                        <Td>{d.main}</Td>
                                        <Td>{d.symptoms}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </> : null);
    }

    function getCategoryNo(c) {
        if (!c) return "：";
        if (c === "keyword") return "・";
        return c.toUpperCase();
    }

    function getWeight(symptoms, t, name) {
        if (symptoms[t]) {
            for (const symptom of symptoms[t]) {
                if (symptom.name === name) {
                    return symptom.lsp;
                }
            }
        }
        return "";
    }

    function updateWeight(disease, type, d, w) {
        const name = d["name"];
        const category = d["category"];

        // isdbのデータを変更してsetupする
        const isdb = db.isdb;
        let match = false;
        for (const item of isdb) {
            if (item.name === disease && item.type === type) {
                for (const symptom of item.symptoms) {
                    if (symptom.name === name) {
                        symptom.lsp = w !== "" ? Number(w) : w;
                        match = true;
                        break;
                    }
                }
                // typeの症状がない場合は追加
                if (!match) {
                    item.symptoms.push({
                        category: category,
                        name: name,
                        lsp: w !== "" ? Number(w) : w
                    });
                    match = true;
                    console.log("match symptom ", name);
                }
                break;
            }
        }
        // typeがない場合は追加
        if (!match) {
            isdb.push({
                name: disease,
                type: type,
                symptoms: [{
                    category: category,
                    name: name,
                    lsp: Number(w)
                }]
            });
            console.log("match type ", type);
        }
        const diseases = MonshinService.buildIsdb(db);
        setDb(db);
        setDeseases(diseases);
        calc(db, diseases);
        setStatus("update");
        console.log(diseases);
    }

    ///////////////////////////////////////////////


    function renderAccuracy() {
        return (<Box>
            <Box>
                {renderSummary()}
            </Box>
            <TableContainer>
                <Table size={"sm"}>
                    <Thead>
                        <Tr>
                            <Th>疾患</Th>
                            <Th>順位</Th>
                            <Th>重み</Th>
                            <Th>症状</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {accs.map((d, j) => {
                            return (
                                <Tr>
                                    <Td>{d.name}</Td>
                                    <Td>{d.rank}</Td>
                                    <Td>{d.weight.toFixed(2)}</Td>
                                    <Td>{d.input}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>);
    }

    function renderSummary() {
        const result = {
            total: 0,
            one: 0,
            three: 0,
            five: 0,
            ten: 0,
            out: 0,
        };
        for (const acc of accs) {
            result.total++;
            if (acc.rank === 1) { result.one++; }
            else if (acc.rank <= 3) { result.three++; }
            else if (acc.rank <= 5) { result.five++; }
            else if (acc.rank <= 10) { result.ten++; }
            else { result.out++; }
        }
        return (<Box p={4}>
            <Text fontSize={"sm"}>
                1位:{result.one}{" / "}
                3位以内:{result.three}{" / "}
                5位以内:{result.five}{" / "}
                10位以内:{result.ten}{" / "}
                圏外:{result.out}{" / "}
                総数:{result.total}
            </Text>
        </Box>);
    }

    // 精度を計算する
    // コモンディジーズがそれぞれの症例の症状を選択した場合に、何位に位置するかを示す。
    async function calc(db, diseases) {
        const accs = [];
        for (const item of db.cases) {
            if (item.main.length > 0) {
                // 診断実行
                const result = diagItem(db, diseases, item);
                // 対象疾患の順位と重みを取得
                let rank = 1;
                for (const d of result) {
                    if (d.name === item.name) {
                        accs.push(
                            {
                                name: d.name,
                                rank: rank,
                                weight: d.w,
                                input: d.input.join(","),
                            }
                        );
                        // console.log(d, result)
                        break;
                    }
                    rank++;
                }
            }
        }
        setAccs(accs);
    }

    function diagItem(db, diseases, item) {
        // 症状リストを取得
        let ss = item.main + "," + item.symptoms;
        if (item.age) { ss += "," + item.age; }
        if (item.sex) { ss += "," + item.sex; }
        if (item.onset) { ss += "," + item.onset; }
        if (item.duration) { ss += "," + item.duration; }
        if (item.time_course) { ss += "," + item.time_course; }
        if (item.repeat) { ss += "," + item.repeat; }
        const symptoms = ss.split(",");
        // 診断実行
        const result = diag(db, diseases, item.main, symptoms);
        return result;
    }

    // 推論
    function diag(db, diseases, main, symptoms) {
        const result = [];
        for (const item of diseases) {
            let match = false;
            for (const symptom of item.symptoms) {
                if (main === symptom.name) {
                    match = true;
                }
            }
            if (match) {
                const w = getW(db, item, symptoms);
                result.push({
                    name: item.name,
                    w: w + item.rate * item.rate,
                    def: item.symptoms,
                    input: symptoms,
                });
            }
        }
        result.sort(function (a, b) {
            const as = a.w;
            const bs = b.w;
            if (as > bs) return -1;
            if (as < bs) return 1;
            return 0;
        });

        return result;
    }

    function getW(db, item, symptoms) {
        let w = 0;
        for (let name of symptoms) {
            name = name.trim();
            let match = false;
            for (const symptom of item.symptoms) {
                if (name === symptom.name) {
                    w += symptom.lsp;
                    match = true;
                }
            }
            if (!match) {
                w += noW(db, name);
            }
        }
        return w;
    }

    function noW(db, s) {
        const nowrds = ['男性', '女性',
            '乳児', '幼児', '小児', '若年', '中年', '高齢', '超高齢', '年齢不問',
            '親', '成人',
            '日単位', '週単位', '月単位', '年単位',
            '突然', '急性', '亜急性', '緩徐', '横ばい',
            '悪化する', '改善する'];
        if (nowrds.includes(s)) {
            return 0;
        }
        for (const symptom of db.symptoms) {
            if (symptom.name === s) {
                return symptom.defw;
            }
        }
        return -9;
    }
}
